import React, { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import useNetworkStatus from "../../../common/NetworkHook";
import { setMyGroup } from "../../../features/appSlice";
import Spinner from "../../../common/Spinner";
import SuccessModal from "../../../common/SuccessModal";
import TextArea from "../../../common/TextArea";
import Select from "react-select";
import {
  createGroup,
  editGroup,
  getMySim,
} from "../../../app/actions/groupAction/groupAction";
import { hideModal } from "../../../app/reducers/groupReducer/groupReducer";

const CreateNewGroup = () => {
  const { isOnline } = useNetworkStatus();

  console.log(isOnline);

  const dispatch = useDispatch();
  const { group_state, error, sim_state, sims, edit, group } = useSelector(
    (state) => state.group
  );
  const groupSim = edit
    ? group?.groupSims?.map((item) => {
        return {
          value: item._id,
          label: item.iccid,
        };
      })
    : [];
  const [simGroup, setSimGroup] = useState(groupSim);
  const simsOption = sims?.data.map((item) => {
    return {
      value: item._id,
      label: item.iccid,
    };
  });

  console.log(simsOption);
  const isDeactivated = group_state === "creating";

  const [description, setDescription] = useState(
    group?.groupData?.description || ""
  );
  const [groupName, setGroupName] = useState(group?.groupData?.name || "");

  const onCancelHandler = () => {
    if (isDeactivated) return;
    dispatch(setMyGroup());
  };

  const onCreateGroupHandler = () => {
    if (!groupName || !description) {
      return;
    }
    const payload = {
      description,
      name: groupName,
      simIds: simGroup.slice(0, simGroup.length).map((item) => item.value),
    };
    console.log(simGroup);
    if (edit) {
      dispatch(
        editGroup({
          _id: group.groupData._id,
          payload,
        })
      );
    } else dispatch(createGroup(payload));

    // setTimeout(() => {

    //   setDeactivated(false);
    // }, 3000);
  };

  const onModalHideHandler = () => {
    dispatch(hideModal());
    dispatch(setMyGroup());
  };
  const handleSelect = (selectedOption) => {
    setSimGroup(selectedOption);
    console.log(simGroup);
  };

  useEffect(() => {
    dispatch(getMySim());
  }, [dispatch]);
  const CreateGroupButton = () => {
    return (
      <>
        {group_state === "creating" ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="col-md-5 ml-auto mb-3 btn btn-light text-white"
            disabled={isDeactivated}
            style={{ height: "3rem", borderRadius: "10px" }}
          >
            <Spinner />
          </motion.button>
        ) : (
          <div
            className="col-md-5 ml-3 mb-3 btn btn-color text-white"
            style={{ height: "3rem", borderRadius: "8px", width: "200px" }}
            onClick={onCreateGroupHandler}
          >
            <p className="mt-1">{edit ? "Edit" : "Create"} Group</p>
          </div>
        )}
      </>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <SuccessModal
        message={`Group ${edit ? "edit" : "created"} successfully!`}
        show={group_state === "created"}
        onHideHandler={onModalHideHandler}
      />

      <div
        className="container-fluid shadow-sm p-2 mb-1 bg-white text-dark rounded"
        style={{ height: "100%" }}
      >
        <div className="">
          <div className="text-left mt-3 ml-3">
            <h5 className="font-weight-bold" style={{ fontSize: "20px" }}>
              {edit ? "Edit" : "Create New"} Group
            </h5>
          </div>

          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Group Name</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Enter First Name"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  style={{ height: "3rem", borderRadius: "10px" }}
                  disabled={isDeactivated}
                />
              </div>

              <div style={{ visibility: "hidden" }}>
                <small>Hidden</small>
                <div className="input-group rounded" />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="text-left mt-3">
                <small>Description</small>
              </div>
              <TextArea
                className="form-control"
                placeholder="Description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={isDeactivated}
              />
            </div>
          </div>

          {/*  */}

          {/* <div className="col-md-6 mb-2">
                            <div className='text-left mt-4'>
                                <small>SIM</small>
                            </div>
                            <SelectListGroup
                                borderRadius='10px'
                                bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                                placeholder='Add SIM'
                                name='addSim'
                                value={addSIM}
                                onChange={(e) => {
                                    if (e.target.value === 'null') {
                                        setAddSIM(null)

                                        return
                                    }

                                    if (e.target.value === 'SIM1') setAddSIM('SIM1')
                                    if (e.target.value === 'SIM2') setAddSIM('SIM2')
                                    if (e.target.value === 'SIM3') setAddSIM('SIM3')
                                }}
                                options={addSIMOptions}
                                disabled={isDeactivated}
                            />

                            <div className='mt-5' style={{ visibility: 'hidden' }}>
                                <div style={{ visibility: 'hidden' }}>
                                    <small>Hidden</small>
                                    <div className='input-group rounded mt-5' />
                                </div>
                            </div>
                            
                            <div className='container-fluid'>
                                <div className='row mt-5'>
                                    <div
                                        className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                                        style={{ height: '3rem', borderRadius: '10px' }}
                                        onClick={onCancelHandler}
                                    >
                                        <p className='mt-1' style={{ cursor: isDeactivated && 'wait' }}>Cancel</p>
                                    </div>
                                    
                                    {
                                        isOnline
                                            ? <CreateGroupButton />
                                            : <div
                                                className='col-md-5 ml-auto mb-3 btn button-style text-dark'
                                                style={{ height: '3rem', borderRadius: '10px' }}
                                            >
                                                <p className='mt-1'>Network Error</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div> */}
        </div>
        <div className="mb-2">
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="row mt-5">
              <div
                className="col-md-5  mb-3 btn button-style text-dark"
                style={{ height: "3rem", borderRadius: "8px", width: "200px" }}
                onClick={onCancelHandler}
              >
                <p className="mt-1" style={{ cursor: isDeactivated && "wait" }}>
                  Cancel
                </p>
              </div>

              <CreateGroupButton />
              {error && group_state === "not_created" && (
                <div
                  className="col-md-5 ml-auto mb-3 btn button-style text-dark"
                  style={{ height: "3rem", borderRadius: "10px" }}
                >
                  <p className="mt-1">
                    {error.message || error || "Something went wrong"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CreateNewGroup;
