import { Navigate, useNavigate } from "react-router-dom";
import { getStoredAuthToken } from "../../utils/authToken";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../app/actions/settingsAction/settingsAction";

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handlePageReload = () => {
      dispatch(getUserProfile());
    };

    window.onload = handlePageReload;

    return () => {
      window.onload = null; // Cleanup the event listener
    };
  }, [dispatch]);
  if (!getStoredAuthToken()) {
    return <Navigate to={"/"} />;
  } else return children;
};

export default RequireAuth;
