import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setShippingAddressStyle } from "../../../../features/appSlice";
import { setOrderAmount } from "../../../../app/reducers/purchaseReducer/purchaseReducer";

const SimSpecification = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  // const [ownerId, setOwnerId] = useState("");

  const onNextHandler = () => {
    const payload = {
      amount,
      // ownerId,
    };
    dispatch(setOrderAmount(payload));
    dispatch(setShippingAddressStyle());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="container-fluid">
        <div
          className="shadow-sm p-1 mb-1 bg-white text-dark rounded mt-1"
          style={{ height: "100%" }}
        >
          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mt-5">
              <div className="mb-2 pl-5">
                <div className="text-left mt-2 mb-3">
                  <h5 className="font-weight-bold" style={{ fontSize: "25px" }}>
                    Order SIM
                  </h5>
                </div>

                <div className="text-left mt-5">
                  <small>Amount</small>
                </div>
                <div className="input-group rounded">
                  <input
                    type="mumber"
                    className="form-control"
                    placeholder="Enter Amount ..."
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{ height: "3rem", borderRadius: "10px" }}
                  />
                </div>

                {/* <div className="text-left mt-2">
                  <small>OwnerId</small>
                </div>
                <div className="input-group rounded">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ownerID ..."
                    value={ownerId}
                    onChange={(e) => setOwnerId(e.target.value)}
                    style={{ height: "3rem", borderRadius: "10px" }}
                  />
                </div> */}

                <div className="container-fluid">
                  <div className="row mt-5">
                    <button
                      className="col-md-12 ml-auto mb-3 btn btn-color text-white"
                      style={{ height: "3rem", borderRadius: "10px" }}
                      onClick={onNextHandler}
                      disabled={!amount}
                    >
                      <p className="mt-1">Next</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6" id="mobile_only">
              <div className="bg-purchase m-5" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SimSpecification;
