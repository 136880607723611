import { createSlice } from "@reduxjs/toolkit";
import { createOrder } from "../../actions/purchaseAction/purchaseAction";

const initialState = {
  amount: "",
  ownerId: "",
  shippingAddress: {},
  order_state: "",
  data: null,
  error: null,
};

const purchaseReducer = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setOrderAmount: (state, action) => {
      state.amount = action.payload.amount;
      state.ownerId = action.payload.ownerId;
    },
    setShipping: (state, action) => {
      console.log(action);
      state.shippingAddress = action.payload;
    },
    resetOrderState: (state, action) => {
      state.order_state = "";
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state, action) => {
      return {
        ...state,
        order_state: "creating",
        error: null,
      };
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      return {
        ...state,
        order_state: "ordered",
        data: action.payload,
      };
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        order_state: "failed",
        error: action.error,
      };
    });
  },
});

export const { setOrderAmount, setShipping, resetOrderState } =
  purchaseReducer.actions;

export default purchaseReducer.reducer;
