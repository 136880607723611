import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import ViewGroup from "./ViewGroup";
import {
  getGroups,
  getMySim,
} from "../../../app/actions/groupAction/groupAction";
import Groups from "./Groups";

const MyGroups = () => {
  const { isViewGroupClicked } = useSelector((state) => state.app.value);
  console.log(isViewGroupClicked);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getMySim());
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <div className="container-fluid">
        {isViewGroupClicked ? <ViewGroup /> : <Groups />}
      </div>
    </motion.div>
  );
};

export default MyGroups;
