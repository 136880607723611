import { createSlice } from "@reduxjs/toolkit";
import {
  addSimToGroup,
  createGroup,
  deleteGroup,
  editGroup,
  getGroupSims,
  getGroups,
  getMySim,
  removeSim,
} from "../../actions/groupAction/groupAction";

const initialState = {
  group_state: "",
  error: "",
  groups: [],
  group: {},
  isDeleting: false,
  sim_state: "",
  sims: null,
  simError: "",
  edit: false,
  deleteSim: false,
  deleteSimState: "",
};
const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    hideModal: (state) => {
      state.group_state = "";
      state.error = "";
      state.deleteSimState = "";
      state.sim_state = "";
    },
    toggleDeleteModal: (state, action) => {
      state.isDeleting = action.payload;
    },
    toggleEditGroup: (state, action) => {
      console.log(action);
      state.edit = action.payload;
    },
    toggeleDeleteSim: (state, action) => {
      state.deleteSim = action.payload;
    },
    resetGroup: (state, action) => {
      state.groups = [];
      state.group = {};
      state.edit = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createGroup.pending, (state, action) => {
      return {
        ...state,
        group_state: "creating",
        error: "",
      };
    });
    builder.addCase(createGroup.fulfilled, (state, _) => {
      return { ...state, group_state: "created", error: "" };
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      return {
        ...state,
        group_state: "not_created",
        error: action.error,
      };
    });
    builder.addCase(editGroup.pending, (state, action) => {
      return {
        ...state,
        group_state: "creating",
        error: "",
      };
    });
    builder.addCase(editGroup.fulfilled, (state, _) => {
      return { ...state, group_state: "created", error: "" };
    });
    builder.addCase(editGroup.rejected, (state, action) => {
      return {
        ...state,
        group_state: "not_created",
        error: action.error,
      };
    });
    builder.addCase(getGroups.pending, (state, action) => {
      return {
        ...state,
        group_state: "getting_groups",
        error: "",
      };
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      return {
        ...state,
        group_state: "got_groups",
        groups: action.payload,
      };
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      return {
        ...state,
        group_state: "",
        error: action.error,
      };
    });
    builder.addCase(getGroupSims.pending, (state, action) => {
      return {
        ...state,
        group_state: "getting_group",
        error: "",
      };
    });
    builder.addCase(getGroupSims.fulfilled, (state, action) => {
      return {
        ...state,
        group_state: "got_group",
        error: "",
        group: action.payload,
      };
    });
    builder.addCase(getGroupSims.rejected, (state, action) => {
      return {
        ...state,
        group_state: "",
        error: action.error,
      };
    });

    builder.addCase(deleteGroup.pending, (state, action) => {
      return {
        ...state,
        group_state: "deleting_group",
        error: "",
        isDeleting: true,
      };
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      return {
        ...state,
        group_state: "group_deleted",
        isDeleting: false,
        error: "",
      };
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      return {
        ...state,
        group_state: "",
        error: action.error,
      };
    });
    builder.addCase(getMySim.pending, (state) => {
      return {
        ...state,
        sim_state: "getting",
      };
    });
    builder.addCase(getMySim.fulfilled, (state, action) => {
      return {
        ...state,
        sim_state: "gotten",
        sims: action.payload,
      };
    });
    builder.addCase(getMySim.rejected, (state, action) => {
      return {
        ...state,
        sim_state: "error",
        simError: action.error,
      };
    });
    builder.addCase(addSimToGroup.pending, (state) => {
      return {
        ...state,
        sim_state: "adding_sim",
      };
    });
    builder.addCase(addSimToGroup.fulfilled, (state, action) => {
      return {
        ...state,
        sim_state: "added",
      };
    });
    builder.addCase(addSimToGroup.rejected, (state, action) => {
      return {
        ...state,
        sim_state: "error_adding",
        simError: action.error,
      };
    });
    builder.addCase(removeSim.pending, (state, action) => {
      return {
        ...state,
        deleteSimState: "remove_sim",
        error: "",
      };
    });
    builder.addCase(removeSim.fulfilled, (state, action) => {
      return {
        ...state,
        deleteSimState: "sim_removed",
        deleteSim: false,
        error: "",
      };
    });
    builder.addCase(removeSim.rejected, (state, action) => {
      return {
        ...state,
        deleteSimState: "",
        error: action.error,
      };
    });
  },
});
export const {
  hideModal,
  toggleDeleteModal,
  toggleEditGroup,
  resetGroup,
  toggeleDeleteSim,
} = groupSlice.actions;
export default groupSlice.reducer;
