import Dashboard from "../components/pages/Dashboard";
import Groups from "../components/pages/Groups";
import LoRa from "../components/pages/LoRa";
import LoRaPlans from "../components/pages/LoRaPlans";
import Settings from "../components/pages/Settings";
import SimPlan from "../components/pages/SimPlan";
import Sims from "../components/pages/Sims";
import User from "../components/pages/User";
import Wallet from "../components/pages/Wallet";
import SendSMS from "../components/pages/simActivities/SendSMS";
import ViewSim from "../components/pages/simActivities/ViewSim";

export const privateRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/sim-cards",
    element: <Sims />,
  },
  {
    path: "/send-sms/sims",
    element: <SendSMS />,
  },
  {
    path: "/sims/sim-card/:id",
    element: <ViewSim />,
  },
  // {
  //   path: "/sim-plans",
  //   element: <SimPlan />,
  // },
  // {
  //   path: "/lora",
  //   element: <LoRa />,
  // },
  // {
  //   path: "/lora-plans",
  //   element: <LoRaPlans />,
  // },
  {
    path: "/billing",
    element: <Wallet />,
  },
  // {
  //   path: "/user",
  //   element: <User />,
  // },
  {
    path: "/groups",
    element: <Groups />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
];
