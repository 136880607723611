import { motion } from "framer-motion";
import Navbar from "../layout/Navbar";
import WillIncludeSidebar from "../../common/WillIncludeSidebar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setBusinessProfile,
  setPasswordSettings,
  setPersonalProfile,
  setSettingsItem,
  setSimClicked,
  setSimItemDropdown,
} from "../../features/appSlice";
import PersonalProfile from "./settingsActivities/PersonalProfile";
import BusinessProfile from "./settingsActivities/BusinessProfile";
import PasswordSettings from "./settingsActivities/PasswordSettings";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../app/actions/settingsAction/settingsAction";
import Spinner from "../../common/Spinner";

const Settings = () => {
  const {
    peronslProfileStyle,
    buinessProfileStyle,
    passwordStyle,
    personalProfileClicked,
    businessProfileClicked,
    passwordSettingsClicked,
  } = useSelector((state) => state.app.value);

  const { user, loading_state, error } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const onPersonalProfileClickHandler = () => dispatch(setPersonalProfile());
  const onBusinessProfileClickHandler = () => dispatch(setBusinessProfile());
  const onPasswordSettingsClickHandler = () => dispatch(setPasswordSettings());

  useEffect(() => {
    dispatch(setSimItemDropdown(false));
    dispatch(setSimClicked(false));
    dispatch(setSettingsItem());
    dispatch(getUserProfile());

    dispatch(setPersonalProfile());
  }, []);
  let content = null;
  if (loading_state === "getting_user") {
    content = PersonalProfile;
  } else {
    if (personalProfileClicked) content = <PersonalProfile user={user} />;
    if (businessProfileClicked) content = <BusinessProfile />;
    if (passwordSettingsClicked) content = <PasswordSettings />;
  }
  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="text-left mt-5 ml-3 mb-5"
          >
            <div className="container-fluid">
              <div className="container-fluid">
                <h5 className="font-weight-bold" style={{ fontSize: "28px" }}>
                  Settings
                </h5>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="continer-fluid mt-5 ml-3 mr-auto">
                    <button
                      className={peronslProfileStyle}
                      onClick={onPersonalProfileClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-person"></i>
                      </span>
                      <span> PERSONAL PROFILE</span>
                    </button>

                    <button
                      className={buinessProfileStyle}
                      onClick={onBusinessProfileClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-briefcase"></i>
                      </span>
                      <span> BUSINESS PROFILE</span>
                    </button>

                    <button
                      className={passwordStyle}
                      onClick={onPasswordSettingsClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-lock"></i>
                      </span>
                      <span> PASSWORD SETTINGS</span>
                    </button>
                  </div>
                </div>
              </div>

              {content}
            </div>
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default Settings;
