import { motion } from "framer-motion";
import Navbar from "../layout/Navbar";
import WillIncludeSidebar from "../../common/WillIncludeSidebar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setGroupItem,
  setSimClicked,
  setMyGroup,
  setCreateNewGroup,
  setViewGroupClicked,
  setDeleteGroup,
  setSimItemDropdown,
} from "../../features/appSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import MyGroups from "./groupActivities/MyGroups";
import CreateNewGroup from "./groupActivities/CreateNewGroup";
import DialogModal from "../../common/DialogModal";
import warningInfo from "../../assets/warning-info.png";
import SuccessModal from "../../common/SuccessModal";
import ModalAction from "../../common/ModalAction";
import Select from "react-select";
import {
  addSimToGroup,
  deleteGroup,
  getGroupSims,
  getMySim,
} from "../../app/actions/groupAction/groupAction";
import {
  hideModal,
  resetGroup,
  toggleDeleteModal,
  toggleEditGroup,
} from "../../app/reducers/groupReducer/groupReducer";

const Groups = () => {
  const {
    myGroupStyle,
    createNewGroupStyle,
    isGroupClicked,
    isCreateNewGroupClicked,
    isViewGroupClicked,
    editGroup,
    viewGroupData,
    isDeleteGroup,
  } = useSelector((state) => state.app.value);
  const { group_state, group, sim_state, isDeleting, edit, sims } = useSelector(
    (state) => state.group
  );

  const dispatch = useDispatch();

  const successModal = group_state === "group_deleted";
  const simAdded = sim_state === "added";
  const [alertModal, setAlertModal] = useState(false);
  const [addSims, setAddSims] = useState(false);

  const [simGroup, setSimGroup] = useState([]);
  const compareObjects = (obj1, obj2) => obj1.iccid === obj2.iccid;
  console.log(sims, group);
  const unSelectedSims = sims?.data.filter(
    (obj2) => !group?.groupSims?.some((obj1) => compareObjects(obj1, obj2))
  );
  console.log(unSelectedSims);
  const simsOption = unSelectedSims?.map((item) => {
    return {
      value: item._id,
      label: item.iccid,
    };
  });
  const onGroupClickHandler = () => {
    dispatch(toggleEditGroup(false));
    dispatch(setMyGroup());
  };

  const onCreateNewGroupHandler = () => {
    if (edit) {
      dispatch(toggleEditGroup(true));
    } else dispatch(resetGroup(false));
    dispatch(setCreateNewGroup());
    dispatch(setViewGroupClicked(false));
  };

  const onDeleteGroupHandler = () => {
    dispatch(setDeleteGroup(true));
    dispatch(toggleDeleteModal(true));
  };

  const onEditNewGroupHandler = () => {
    dispatch(toggleEditGroup(true));
    dispatch(setCreateNewGroup());
    dispatch(setViewGroupClicked(false));
  };

  const onCancelClickHandler = () => {
    dispatch(toggleDeleteModal(false));
  };
  const onConfirmRemoveOrDeleteHandler = () => {
    dispatch(deleteGroup(group.groupData._id));
  };

  const onModalHideHandler = () => {
    dispatch(hideModal());
    dispatch(setViewGroupClicked(false));

    dispatch(setMyGroup());
  };
  const onAddSims = () => {
    if (!sims) {
      dispatch(getMySim());
    }
    setAddSims(true);
  };
  const handleSelect = (selectedOption) => {
    setSimGroup(selectedOption);
    console.log(simGroup);
  };
  const handleAddSim = () => {
    const payload = {
      groupId: group.groupData._id,
      simsIds: { simIds: simGroup.map((item) => item.value) },
    };
    console.log(payload);
    dispatch(addSimToGroup(payload));
    setSimGroup([]);
  };
  const handleHide = () => {
    setAddSims(false);
    dispatch(hideModal());
    dispatch(getGroupSims(group.groupData._id));
  };

  useEffect(() => {
    dispatch(setSimItemDropdown(false));
    dispatch(setSimClicked(false));
    dispatch(setGroupItem());

    dispatch(setMyGroup());
  }, [dispatch]);

  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="text-left mt-5 ml-3 mb-5"
          >
            <ModalAction
              body="UI not available!"
              show={alertModal}
              onHideHandler={() => setAlertModal(false)}
            />
            <SuccessModal
              message={`${group?.groupData?.name} was deleted successfully!`}
              show={successModal}
              onHideHandler={onModalHideHandler}
            />
            <SuccessModal
              message={`SIM was added successfully!`}
              show={simAdded}
              onHideHandler={handleHide}
            />
            <DialogModal show={addSims && sim_state !== "added"}>
              <div className="continer-fluid mt-3 ml-3 mr-auto">
                <div style={{ paddingBlock: "3rem" }}>
                  <Select
                    closeMenuOnSelect={true}
                    isLoading={sim_state === "getting"}
                    defaultValue={{ label: "Select Sims", value: "null" }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleSelect}
                    value={simGroup}
                    options={simsOption || []}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <button
                        className="mr-3 mb-3 btn btn-light "
                        style={{ borderColor: "black", color: "black" }}
                        onClick={() => setAddSims(false)}
                        disabled={sim_state === "adding_sim"}
                      >
                        <span>Cancel</span>
                      </button>

                      <button
                        className="mr-3 mb-3 btn btn-color text-light text-white"
                        onClick={handleAddSim}
                        disabled={sim_state === "adding_sim"}
                      >
                        <span>
                          {sim_state === "adding_sim" ? "Adding" : "Add"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </DialogModal>

            <DialogModal show={isDeleting}>
              <img src={warningInfo} alt="success logo" width="30%" />
              <div>You are about to delete {group?.groupData?.name}!</div>

              <div className="continer-fluid mt-3 ml-3 mr-auto">
                <button
                  className="mr-3 mb-3 btn btn-light text-danger text-white"
                  style={{ borderColor: "red" }}
                  onClick={onCancelClickHandler}
                  disabled={group_state === "deleting_group"}
                >
                  <span>Cancel</span>
                </button>

                <button
                  className="mr-3 mb-3 btn btn-danger text-white"
                  onClick={onConfirmRemoveOrDeleteHandler}
                  disabled={group_state === "deleting_group"}
                >
                  <span>
                    {group_state === "deleting_group" ? "Deleting" : "Delete"}
                  </span>
                </button>
              </div>
            </DialogModal>

            <div className="container-fluid">
              <div className="container-fluid">
                <h5 className="font-weight-bold" style={{ fontSize: "28px" }}>
                  Groups
                </h5>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="continer-fluid mt-5 ml-3 mr-auto">
                    <button
                      className={myGroupStyle}
                      onClick={onGroupClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-option"></i>
                      </span>
                      <span> MY GROUP</span>
                    </button>

                    <button
                      className={createNewGroupStyle}
                      style={{ visibility: editGroup ? "hidden" : "visible" }}
                      onClick={onCreateNewGroupHandler}
                    >
                      <span>
                        <i className="fs-4 bi-pen"></i>
                      </span>
                      <span> CREATE NEW GROUP</span>
                    </button>
                  </div>

                  {group_state === "got_group" && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 1 } }}
                      exit={{ opacity: 0 }}
                      // style={{ visibility: isViewGroupClicked ? 'visible' : 'hidden' }}
                      className="continer-fluid mt-5 ml-3"
                    >
                      <button
                        className="mr-3 mb-3 btn "
                        style={{ borderColor: "green", color: "green" }}
                        onClick={onAddSims}
                      >
                        <span>+</span> <span>Add Sims</span>
                      </button>
                      <button
                        className="mr-3 mb-3 btn btn-light text-danger button-hover"
                        style={{ borderColor: "red" }}
                        onClick={onDeleteGroupHandler}
                      >
                        <span>Delete Group</span>
                      </button>

                      <button
                        className="mr-3 mb-3 btn btn-color text-light"
                        onClick={onEditNewGroupHandler}
                      >
                        <span>Edit Group</span>
                      </button>
                    </motion.div>
                  )}
                </div>
              </div>
              {console.log(isGroupClicked)}
              {isGroupClicked && <MyGroups />}
              {isCreateNewGroupClicked && <CreateNewGroup />}
            </div>
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default Groups;
