import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setSimSpecsStyle } from "../../../../features/appSlice";
import { usePaystackPayment } from "react-paystack";
import { useEffect } from "react";
import ModalAction from "../../../../common/ModalAction";
import { useState } from "react";
import useNetworkStatus from "../../../../common/NetworkHook";
import { createOrder } from "../../../../app/actions/purchaseAction/purchaseAction";
import SuccessModal from "../../../../common/SuccessModal";
import { resetOrderState } from "../../../../app/reducers/purchaseReducer/purchaseReducer";
import Spinner from "../../../../common/Spinner";

const Checkout = () => {
  const { isOnline } = useNetworkStatus();
  const { amount, shippingAddress, data, error, order_state, ownerId } =
    useSelector((state) => state.purchase);

  // console.log(isOnline)

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  let errMessage;

  if (error && error.message) {
    errMessage = error.message;
  }

  const handleOrder = () => {
    const payload = {
      amount,
      ownerId,
      shippingAddress,
    };
    dispatch(createOrder(payload));
  };

  const handleHideModal = () => {
    dispatch(resetOrderState());
    dispatch(setSimSpecsStyle());
  };
  const onBackClickHandler = () => {
    dispatch(setSimSpecsStyle());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <ModalAction
        body={errMessage}
        show={error}
        onHideHandler={() => handleHideModal()}
      />
      <SuccessModal
        show={data}
        onHideHandler={() => handleHideModal()}
        message={"Order Created Successfully"}
      />

      <div className="container-fluid">
        <div
          className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-1"
          style={{ height: "100%" }}
        >
          <div className="row d-flex ml-4 mr-4">
            <div className="container">
              <div className="container mb-5">
                <div className="text-left mt-5">
                  <h5 className="font-weight-bold">
                    Please review your entries
                  </h5>
                </div>

                {/*  */}

                <div className="text-left mt-5">
                  <h6 className="font-weight-bold">SIM </h6>
                </div>
                <div className="row text-left mt-4">
                  <div className="col-md-3 txt-color-light">
                    <h5>Amount</h5>
                  </div>
                  <div className="col-md-2">
                    <h5>N{amount}</h5>
                  </div>
                </div>
              </div>

              {/*  */}

              <div className="text-left mt-5">
                <h6 className="font-weight-bold">Delivery Details</h6>
              </div>
              <div className="row text-left mt-4">
                <div className="col-md-3 txt-color-light">
                  <h5>Country</h5>
                </div>
                <div className="col-md-6">
                  <h5>{shippingAddress.country}</h5>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>City</h5>
                </div>
                <div className="col-md-6">
                  <h5>{shippingAddress.city}</h5>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>Street</h5>
                </div>
                <div className="col-md-6">
                  <h5>{shippingAddress.street}</h5>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>Postal Code Number</h5>
                </div>
                <div className="col-md-6">
                  <h5>{shippingAddress.postalCode}</h5>
                </div>
              </div>
              {/* <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>Company Name</h5>
                </div>
                <div className="col-md-6">
                  <h5>{user.companyName}</h5>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>Company Phone</h5>
                </div>
                <div className="col-md-6">
                  <h5>{user.phoneNumber}</h5>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-md-3 txt-color-light">
                  <h5>Email</h5>
                </div>
                <div className="col-md-6">
                  <h5>{user.email}</h5>
                </div>
              </div> */}

              {/*  */}

              <div className="text-left mt-5">
                {order_state === "creating" ? (
                  <div style={{ paddingBottom: "70px", maxWidth: "200px" }}>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <button
                      className="mb-4 mr-4 btn button-style text-dark"
                      style={{
                        height: "3rem",
                        width: "10rem",
                        borderRadius: "10px",
                      }}
                      onClick={onBackClickHandler}
                    >
                      <p className="mt-1">Cancel</p>
                    </button>
                    <button
                      className="mb-4 btn btn-color text-white"
                      style={{
                        height: "3rem",
                        width: "10rem",
                        borderRadius: "10px",
                      }}
                      onClick={handleOrder}
                    >
                      <p className="mt-1">Confirm</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Checkout;
