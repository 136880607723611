import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import {
  setCheckoutStyle,
  setSimSpecsStyle,
} from "../../../../features/appSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import useNetworkStatus from "../../../../common/NetworkHook";
import { setShipping } from "../../../../app/reducers/purchaseReducer/purchaseReducer";

const ShippingAddress = () => {
  const { isOnline } = useNetworkStatus();

  console.log(isOnline);

  const dispatch = useDispatch();

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  let isDisabled = true;
  if (city && region && address && postalCode && country) {
    isDisabled = false;
  }
  console.log(isDisabled);

  const onCancelHandler = () => {
    dispatch(setSimSpecsStyle());
  };

  const onNextHandler = () => {
    const shipping = {
      street: address,
      city,
      state: region,
      postalCode,
      country,
    };
    console.log(shipping);
    dispatch(setShipping(shipping));
    dispatch(setCheckoutStyle());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="container-fluid">
        <div
          className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-1"
          style={{ height: "100%" }}
        >
          <div className="text-left mt-3 ml-3">
            <h5 className="font-weight-bold" style={{ fontSize: "25px" }}>
              Shipping Information
            </h5>
          </div>

          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Postal Code</small>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Your name..."
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                style={{ height: "3rem", borderRadius: "10px" }}
              />

              <div className="text-left mt-4">
                <small>City</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your address..."
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ height: "3rem", borderRadius: "10px" }}
                />
              </div>

              <div className="text-left mt-3">
                <small>Country</small>
              </div>
              <CountryDropdown
                className="form-control form-control-lg bg-transparent text-dark"
                value={country}
                onChange={setCountry}
                style={{ borderRadius: "10px" }}
              />
            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Address</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your address..."
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ height: "3rem", borderRadius: "10px" }}
                />
              </div>

              <div className="text-left mt-3">
                <small>State</small>
              </div>
              <RegionDropdown
                className="form-control form-control-lg bg-transparent text-dark"
                country={country}
                value={region}
                onChange={setRegion}
                placeholder="Select State"
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="container-fluid">
              <div className="row mt-5">
                <div
                  className="col-md-5 mr-auto mb-3 btn button-style text-dark"
                  style={{ height: "3rem", borderRadius: "10px" }}
                  onClick={onCancelHandler}
                >
                  <p className="mt-1">Back</p>
                </div>

                {isOnline ? (
                  <button
                    className="col-md-5 ml-auto mb-3 btn btn-color text-white"
                    style={{ height: "3rem", borderRadius: "10px" }}
                    onClick={onNextHandler}
                    disabled={isDisabled}
                  >
                    <p className="mt-1">Next</p>
                  </button>
                ) : (
                  <div
                    className="col-md-5 ml-auto mb-3 btn button-style text-dark"
                    style={{ height: "3rem", borderRadius: "10px" }}
                  >
                    <p className="mt-1">Network Error</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ShippingAddress;
