import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import SelectListGroup from "../../../common/SelectListGroup";
import { useDispatch, useSelector } from "react-redux";
import useNetworkStatus from "../../../common/NetworkHook";
import Spinner from "../../../common/Spinner";
import SuccessModal from "../../../common/SuccessModal";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { PREVIOUS_ROUTE } from "../../../app/constants";
import { setSimItemDropdown } from "../../../features/appSlice";
import { changePassword } from "../../../app/actions/settingsAction/settingsAction";
import { hideModal } from "../../../app/reducers/userReducer/userReducer";
import { removeStoredAuthToken } from "../../../utils/authToken";

const PasswordSettings = () => {
  const { isOnline } = useNetworkStatus();

  console.log(isOnline);

  const dispatch = useDispatch();
  const { loading_state } = useSelector((state) => state.settings);
  const navigate = useNavigate();

  //   const [isDeactivated, setDeactivated] = useState(false);
  const isDeactivated = loading_state === "changing_password";
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasword, setConfirmPassword] = useState("");
  const [successModal, showSuccessModal] = useState(false);

  const onCancelHandler = () => {
    if (isDeactivated) return;
    if (localStorage.getItem(PREVIOUS_ROUTE) == "/sim-cards")
      dispatch(setSimItemDropdown(true));
    navigate(localStorage.getItem(PREVIOUS_ROUTE));
  };

  const onSaveChangesHandler = () => {
    const payload = {
      oldPassword,
      newPassword,
    };
    dispatch(changePassword(payload));
  };

  const onModalHideHandler = () => {
    dispatch(hideModal());
  };

  const SaveChangesButton = () => {
    return (
      <>
        {loading_state === "changing_password" ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="col-md-5 ml-auto mb-3 btn btn-light text-white"
            disabled={isDeactivated}
            style={{ height: "3rem", borderRadius: "10px" }}
          >
            <Spinner />
          </motion.button>
        ) : (
          <div
            className="col-md-5 ml-auto mb-3 btn btn-color text-white"
            style={{ height: "3rem", borderRadius: "10px" }}
            onClick={onSaveChangesHandler}
          >
            <p className="mt-1">Save Changes</p>
          </div>
        )}
      </>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
    >
      <SuccessModal
        message="Password saved successfully!"
        show={loading_state === "password_changed"}
        onHideHandler={onModalHideHandler}
      />

      <div className="container-fluid">
        <div
          className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-3"
          style={{ height: "100%" }}
        >
          <div className="text-left mt-3 ml-3">
            <h5 className="font-weight-bold" style={{ fontSize: "20px" }}>
              Edit Password
            </h5>
          </div>

          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Old Password</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  style={{ height: "3rem", borderRadius: "10px" }}
                  disabled={isDeactivated}
                />
              </div>

              <div className="text-left mt-3">
                <small>New Password</small>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />

              <div style={{ visibility: "hidden" }}>
                <div className="text-left mt-5">
                  <small>Hidden</small>
                </div>
                <div className="input-group rounded" />
              </div>
            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Confirm Password</small>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                value={confirmPasword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />

              <div style={{ visibility: "hidden" }}>
                <div className="text-left mt-5">
                  <small>Hidden</small>
                </div>
                <div className="input-group rounded" />
              </div>

              <div style={{ visibility: "hidden" }}>
                <div className="text-left mt-5">
                  <small>Hidden</small>
                </div>
                <div className="input-group rounded" />
              </div>

              <div className="container-fluid">
                <div className="row mt-5">
                  <div
                    className="col-md-5 mr-auto mb-3 btn button-style text-dark"
                    style={{ height: "3rem", borderRadius: "10px" }}
                    onClick={onCancelHandler}
                  >
                    <p
                      className="mt-1"
                      style={{ cursor: isDeactivated && "wait" }}
                    >
                      Back
                    </p>
                  </div>

                  {isOnline ? (
                    <SaveChangesButton />
                  ) : (
                    <div
                      className="col-md-5 ml-auto mb-3 btn button-style text-dark"
                      style={{ height: "3rem", borderRadius: "10px" }}
                    >
                      <p className="mt-1">Network Error</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PasswordSettings;
