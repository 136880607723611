import Select from "react-select";
import prev from "./../assets/prev.svg";
import next from "./../assets/next.svg";
const Pagination = ({
  options,
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  totalItems,
}) => {
  const btnStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "5px",
    border: "0",
    marginRight: "5px",
    cursor: "pointer",
  };
  const btnActive = {
    ...btnStyle,
    background: "#7de495",
    color: "#fff",
  };

  const pages = [];
  const generatePageNumbers = () => {
    const maxButtons = 3;
    const halfMaxButtons = Math.floor(maxButtons / 2);

    // Calculate the start and end page numbers based on the current page
    let startPage = Math.max(1, currentPage - halfMaxButtons);
    const endPage = Math.min(startPage + maxButtons - 1, totalPages);

    // Adjust the start page if we're at the end of the pagination
    startPage = Math.max(1, endPage - maxButtons + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  return (
    <div
      style={{
        padding: "30px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="react-select "
        style={{
          minWidth: "220px",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "600",
          alignItems: "center",
        }}
      >
        <span>Showing</span>
        <Select
          className="basic-single"
          defaultValue={options[0]}
          options={options}
          onChange={({ value }) => onPageChange(1, value)}
          value={{
            value: rowsPerPage,
            label: rowsPerPage,
          }}
        />
        <span>out of {totalItems}</span>
      </div>
      <div
        className=""
        style={{
          display: "flex",
        }}
      >
        {/* {generatePageNumbers().map((page) =>)} */}
        <button
          style={btnStyle}
          onClick={() => onPageChange(currentPage - 1, rowsPerPage)}
          disabled={currentPage === 1}
        >
          <img src={prev} alt="next button" />
        </button>
        {generatePageNumbers().map((page) => (
          <button
            style={currentPage === page ? btnActive : btnStyle}
            onClick={() => onPageChange(page, rowsPerPage)}
            key={page}
          >
            {page}
          </button>
        ))}
        <button
          style={btnStyle}
          onClick={() => onPageChange(currentPage + 1, rowsPerPage)}
          disabled={currentPage === totalPages}
        >
          <img src={next} alt="next button" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
