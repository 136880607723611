import { useState, useEffect } from "react";

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsOnline(true);
      // fetch('https://www.gentro.io/', {
      //   mode: 'no-cors',
      // })
      //   .then(() => !isOnline && setIsOnline(true))
      //   .catch(() => isOnline && setIsOnline(false))
    }, 1000);

    return () => clearInterval(interval);
  }, [isOnline]);

  return { isOnline };
};

export default useNetworkStatus;
