import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import styled from 'styled-components'
import Navbar from "../layout/Navbar";
import WillIncludeSidebar from "../../common/WillIncludeSidebar";
import { Chart } from "react-google-charts";
import {
  barOptions,
  dataOptions,
  geoData,
  // pieData,
  pieOptions,
  usedData,
} from "../data/dataJson";
import {
  getDimensions,
  setDashItem,
  setSimClicked,
  setSimItemDropdown,
} from "../../features/appSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CheckBox from "../../common/CheckBox";
import { getAllBills } from "../../app/actions/billAction/billAction";
import getStringMonth from "../../utils/month";

const Dashboard = () => {
  const { windowDimension } = useSelector((state) => state.app.value);
  const { stat, chart } = JSON.parse(localStorage.getItem("user"));
  const [value, setValue] = useState("");
  const pieData = [
    ["Task", "Hours per Day"],
    ["Active", stat.activeSim],
    ["Inactive", stat.inactiveSim],
    ["Deactivated", stat.deactivatedSim], // CSS-style declaration
  ];
  const barData = [
    [
      "Element",
      "MB Used",

      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ];

  chart.map((item) => {
    barData.push([
      `${getStringMonth(item._id.split("-")[1])}`,
      item.totalDataUsage,
      "color: #106437",
      "SIM",
    ]);
  });
  console.log(barData);
  const dispatch = useDispatch();
  const { bills } = useSelector((state) => state.billing);

  const setDimensions = () => {
    dispatch(
      getDimensions({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      })
    );
  };

  useEffect(() => {
    // setDimensions()
    dispatch(setSimItemDropdown(false));
    dispatch(setSimClicked(false));
    dispatch(setDashItem());
  }, []);

  useEffect(() => {
    dispatch(
      getAllBills({
        page: 1,
        limit: 10000,
      })
    );
  }, [dispatch]);

  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="container-fluid"
          >
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="text-left mt-5 mb-5">
                  <h5 className="font-weight-bold" style={{ fontSize: "28px" }}>
                    Dashboard
                  </h5>
                </div>
              </div>

              <div className="container-fluid">
                <div className="container-fluid h-100 mb-5">
                  {windowDimension.winWidth < 541 && (
                    <div className="row d-flex h-100">
                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>All SIMs</small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.totalSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-success rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Active SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.activeSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-muted rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Inactive SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.inactiveSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-danger rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Deactivated SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.deactivatedSim}
                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}

                  {windowDimension.winWidth >= 541 && (
                    <div className="row d-flex h-100">
                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>All SIMs</small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.totalSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="ml-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-success rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Active SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.activeSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="ml-auto mr-auto"></div>

                      <div className="mr-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-muted rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Inactive SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.inactiveSim}
                            </div>
                          </h5>
                        </div>
                      </div>

                      <div className="ml-auto">
                        <div
                          className="shadow-sm p-3 mb-3 bg-white text-danger rounded"
                          style={{ height: "6rem", width: "13rem" }}
                        >
                          <h5>
                            <small style={{ fontSize: "21px" }}>
                              Deactivated SIMs
                            </small>
                            <div
                              className="col-md-12 font-weight-bold"
                              style={{ fontSize: "38px" }}
                            >
                              {stat.deactivatedSim}
                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-7 mb-3">
                    {chart.length === 0 ? (
                      "No data available"
                    ) : (
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={barData}
                        options={barOptions}
                      />
                    )}
                  </div>
                  <div className="col-md-5 mb-3">
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="400px"
                      data={pieData}
                      options={pieOptions}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="container-fluid mt-3">
                <table className="table shadow-sm p-1 mb-1 bg-white text-dark rounded">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">#</th>
                      <th scope="col">SIM Name</th>
                      <th scope="col">ICCID Number</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  {usedData.map((datum) => (
                    <tbody key={datum.id}>
                      <tr>
                        <td></td>
                        <td scope="row">
                          <CheckBox
                            id={datum.id}
                            value={value}
                            onCheckedHandler={() => setValue(datum.id)}
                          />
                        </td>
                        <td>{datum.id}</td>
                        <td>{datum.deviceName}</td>
                        <td>{datum.iccid}</td>
                        <td>
                          {datum.status === "Active" && (
                            <div className="card color-border-active text-success">
                              Active
                            </div>
                          )}
                          {datum.status === "Inactive" && (
                            <div className="card color-border-inactive text-muted">
                              Inactive
                            </div>
                          )}
                          {datum.status === "Deactivated" && (
                            <div className="card color-border-deactivated text-danger">
                              Deactivated
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>

              <div className="col-md-12 mt-5">
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={data}
                  options={dataOptions}
                />
              </div>

              <div className="col-md-12 mt-5">
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geoData[selection[0].row + 1];
                        console.log("Selected : " + region);
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={geoData}
                  // options={dataOptions}
                />
              </div> */}
            </div>
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

// const CardStyle = styled.div`
//   margin: 4rem, 0rem;
// `;

export default Dashboard;
