import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import {
  barData,
  barOptions,
  data,
  dataOptions,
  historyData,
} from "../../data/dataJson";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import {
  setFundWallet,
  setPaynowClicked,
  setWallet,
} from "../../../features/appSlice";
import CheckBox from "../../../common/CheckBox";
import { usePaystackPayment } from "react-paystack";
import {
  getAllBills,
  payBill,
} from "../../../app/actions/billAction/billAction";
import Spinner from "../../../common/Spinner";
import ModalAction from "../../../common/ModalAction";
import { resetPayBill } from "../../../app/reducers/billReducer/billReducer";
import Pagination from "../../../common/Pagination";
import formateDate, { months } from "../../../utils/formatDate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePdf from "./InvoicePdf";

const MyWallet = () => {
  const { exchangeRate, currency, curreny_text } = useSelector(
    (state) => state.app.value
  );
  const { loading_state, error, bills, payBillSuccess, payBillError } =
    useSelector((state) => state.billing);
  const { email, phoneNumber, lastName, firstName } = JSON.parse(
    localStorage.getItem("user")
  );
  const dispatch = useDispatch();

  const [availableFunds, setAvailableFunds] = useState(100067); // Dollar value rendered to 2 decumal place
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const onSuccess = (response, id, amount) => {
    console.log(response);
    const payload = {
      billId: id,
      paystackRef: response.reference,
      amountInNaira: amount,
    };
    dispatch(payBill(payload));
  };

  const onClose = () => {};
  const handleHideModal = () => {
    dispatch(resetPayBill());
  };
  const handleChange = (page, limit) => {
    dispatch(
      getAllBills({
        page,
        limit,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getAllBills({
        page: 1,
        limit: 10,
      })
    );
  }, [dispatch]);

  const PayNow = ({ data }) => {
    const amount =
      curreny_text === "NGN" ? data.amount * bills.conversionRate : data.amount;
    const config = {
      reference: "gentroconnect_" + new Date().getTime().toString(),
      metadata: {
        custom_fields: [
          {
            display_name: "Description",
            variable_name: "Description",
            value: "Wallet funds",
          },

          {
            display_name: "Phone Number",
            variable_name: "Phone Number",
            value: phoneNumber,
          },

          {
            display_name: "Email",
            variable_name: "Email",
            value: email,
          },
        ],
      },
      email: email,
      amount: amount * 100,
      currency: curreny_text,
      publicKey: "pk_test_c6654b538da3016e1c45af299bd86f97f2401750",
    };
    const initializePayment = usePaystackPayment(config);
    return (
      <button
        className="btn button-style"
        // disabled={data.isPaid}
        style={{
          fontSize: "15px",
          backgroundColor: `${!data.isPaid ? "#1D6900" : "#E1E1E1"}`,
          border: "0px",
          color: "#fff",
          marginRight: "20px",
        }}
        onClick={() => {
          initializePayment((response) => {
            onSuccess(response, data._id, amount);
          }, onClose);
        }}
        disabled={true}
      >
        Pay Now
      </button>
    );
  };
  let content;
  if (loading_state === "getting_bills") {
    content = <Spinner useSpinnarLarge={true} />;
  }
  if (loading_state === "got_bills") {
    content = (
      <motion.div
        initial={{ opacity: 0, background: "white", padding: "20px" }}
        animate={{
          opacity: 1,
          background: "white",
          paddingBlock: "20px",
          transition: { duration: 0.5 },
        }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid">
          <div className="row mt-5 mb-4 text-center">
            <div className="col-md-6">
              <div
                className="shadow-sm p-3 mb-3 bg-white"
                style={{ height: "7rem", borderRadius: "10px" }}
              >
                <h5>
                  <span
                    className="font-weight-bold"
                    style={{ fontSize: "38px" }}
                  >
                    <CurrencyFormat
                      value={bills.totalBill.toFixed(2)}
                      displayType={"text"}
                      isNumericString={true}
                      thousandSeparator={true}
                      renderText={(value) => (
                        <div>
                          <small>
                            <small>{currency}</small>
                          </small>
                          {value}
                        </div>
                      )}
                    />
                  </span>
                  <p className="txt-color-light" style={{ fontSize: "16px" }}>
                    Total Bill
                  </p>
                </h5>
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="shadow-sm p-3 mb-3 bg-white text-danger"
                style={{ height: "7rem", borderRadius: "10px" }}
              >
                <h5>
                  <span
                    className="font-weight-bold"
                    style={{ fontSize: "38px" }}
                  >
                    <CurrencyFormat
                      value={bills.outstandingDebt.toFixed(2)}
                      displayType={"text"}
                      isNumericString={true}
                      thousandSeparator={true}
                      renderText={(value) => (
                        <div>
                          <small>
                            <small>{currency}</small>
                          </small>
                          {value}
                        </div>
                      )}
                    />
                  </span>
                  <p className="txt-light-red" style={{ fontSize: "16px" }}>
                    Outstanding Debt
                  </p>
                </h5>
              </div>
            </div>
          </div>

          <div
            className="form-control"
            style={{
              height: "6rem",
              margin: "auto",
              display: "grid",
              placeItems: "center",
              maxWidth: "75rem",
              borderRadius: "10px",
              background:
                "linear-gradient(92.09deg, #4D9200 41.14%, #A8FBCF 72.86%)",
            }}
          >
            <div className="row">
              <div className="col-md-6 text-white px-3 ml-auto mr-auto btn">
                <CurrencyFormat
                  value={bills.outstandingDebt.toFixed(2)}
                  displayType={"text"}
                  isNumericString={true}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <div className="text-left">
                      You have an outstanding debt of {"  "}
                      {currency}
                      {value}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="shadow-sm p-2 bg-white text-dark rounded mt-5"
            style={{ height: "100%" }}
          >
            <div className="container-fluid">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}
                style={{ maxWidth: "100%", overflowX: "auto" }}
              >
                <div className="text-left mt-3 mb-3">
                  <h5 className="font-weight-bold">Invoice History</h5>
                </div>

                {bills.bills.data.length === 0 && (
                  <div>No invoice available</div>
                )}
                {bills.bills.data.length > 0 && (
                  <>
                    <table className="table shadow-sm p-1 mb-4 mt-5 bg-white text-dark rounded">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">MONTH</th>
                          <th scope="col">AMOUNT</th>
                          <th scope="col">DATE</th>
                          {/* <th scope="col">EMAIL</th> */}
                          <th scope="col">STATUS</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      {bills.bills.data.map((datum) => (
                        <tbody key={datum._id}>
                          <tr>
                            <td></td>
                            <td>
                              <CheckBox
                                id={datum.id}
                                value={""}
                                onCheckedHandler={() => {}}
                              />
                            </td>
                            <td>{months[datum.month]}</td>
                            <td>
                              <CurrencyFormat
                                value={
                                  curreny_text == "NGN"
                                    ? (
                                        datum.amount * bills.conversionRate
                                      ).toFixed(2)
                                    : datum.amount.toFixed(2)
                                }
                                displayType={"text"}
                                isNumericString={true}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {curreny_text}
                                    {value}
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              {datum.month}/{datum.year}
                            </td>
                            {/* <td>hedristemitope2001@gmail.com</td> */}
                            <td
                              style={
                                datum.isPaid
                                  ? { color: "#77B285", fontSize: "14px" }
                                  : { color: "#CD4647", fontSize: "14px" }
                              }
                            >
                              {!datum.isPaid ? "Unpaid" : "Paid"}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PayNow data={datum} />
                              {/* <div
                                className="btn button-style"
                                style={{
                                  height: "35px",
                                  fontSize: "15px",
                                }}
                              >
                                Download
                              </div> */}
                              <PDFDownloadLink
                                document={
                                  <InvoicePdf
                                    data={datum}
                                    name={`${firstName} ${lastName}`}
                                  />
                                }
                                fileName="invoice.pdf"
                                style={{
                                  height: "35px",
                                  fontSize: "15px",
                                  color: "inherit",
                                }}
                                className="btn button-style"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download"
                                }
                              </PDFDownloadLink>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <Pagination
                      options={options}
                      totalPages={bills.bills.meta.totalPages}
                      totalItems={bills.bills.meta.totalItems}
                      currentPage={Number(bills.bills.meta.currentPage)}
                      onPageChange={handleChange}
                      rowsPerPage={Number(bills.bills.meta.count)}
                    />
                  </>
                )}
              </motion.div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 mt-5">
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={dataOptions}
          />
        </div>

        <div className="col-md-8 ml-auto mr-auto mt-5 mb-3">
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={barData}
            options={barOptions}
          />
        </div> */}
      </motion.div>
    );
  }
  if (!loading_state && error) {
    content = <div>{error.message}</div>;
  }
  return (
    <>
      {content}{" "}
      <ModalAction
        show={payBillError ? true : false}
        body={payBillError?.message}
        onHideHandler={handleHideModal}
      />
    </>
  );
};

export default MyWallet;
