import { createSlice } from "@reduxjs/toolkit";
import { getAllSims, getSim } from "../../actions/simAction/simAction";
const initialState = {
  allSims: [],
  errorAllSims: "",
  state: "",
  simState: "",
  sim: null,
  filteredData: [],
  simError: "",
  searchedImsi: "",
  searchedIccid: "",
  status: false,
};

const simSlice = createSlice({
  name: "sim",
  initialState,
  reducers: {
    fiterSimByIccidAndImsi: (state, action) => {
      const { imsi, iccid } = action.payload;
      console.log(state.allSims);
      state.searchedIccid = iccid;
      state.searchedImsi = imsi;
      // Filter the sims based on iccid
      state.filteredData = state.allSims.data.filter((item) => {
        console.log(item.iccid.includes(iccid));
        return (
          item.iccid.includes(iccid) &&
          item.imsiMsisdnPairs[0].imsi.includes(imsi)
        );
      });
    },
    filterSimByStatus: (state, action) => {
      const { all, suspended, active, inactive } = action.payload;
      if (state.allSims.length === 0) {
        state.filteredData = []; // Default to an empty array to prevent errors
        return;
      }
      if (all || (!suspended && !active && !inactive)) {
        state.filteredData =
          state.filteredData.length > 0
            ? state.filteredData
            : state.allSims.data;
      } else {
        const statusToInclude = [];
        if (active) statusToInclude.push("ACTIVE");
        if (inactive) statusToInclude.push("INACTIVE");
        if (suspended) statusToInclude.push("SUSPENDED");
        console.log(statusToInclude);
        const sourceData =
          state.filteredData.length > 0
            ? state.filteredData
            : state.allSims.data;
        state.filteredData = sourceData.filter((item) =>
          statusToInclude.includes(item.status)
        );
        state.status = true;
      }

      console.log(state.filteredData);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSims.fulfilled, (state, action) => ({
      ...state,
      allSims: action.payload,
      filteredData: [],
      state: "",
    }));
    builder.addCase(getAllSims.pending, (state, action) => ({
      ...state,
      state: "loading",
    }));
    builder.addCase(getAllSims.rejected, (state, action) => ({
      ...state,
      state: "",
      error: action.error,
    }));
    builder.addCase(getSim.pending, (state, action) => ({
      ...state,
      simState: "loading",
    }));
    builder.addCase(getSim.fulfilled, (state, action) => ({
      ...state,
      simState: "",
      sim: action.payload,
    }));
    builder.addCase(getSim.rejected, (state, action) => ({
      ...state,
      simState: "error",
      simError: action.error,
    }));
  },
});
export const { fiterSimByIccidAndImsi, filterSimByStatus } = simSlice.actions;
export default simSlice.reducer;
