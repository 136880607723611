import React, { useState } from "react";
import { motion } from "framer-motion";
import { groupData } from "../../data/dataJson";
import { useSelector } from "react-redux";
import active from "../../../assets/active.png";
import inactive from "../../../assets/inactive.png";
import suspended from "../../../assets/suspended.png";
import { useDispatch } from "react-redux";
import {
  setDeleteGroup,
  setMyGroup,
  setViewGroupClicked,
} from "../../../features/appSlice";
import warningInfo from "../../../assets/warning-info.png";
import DialogModal from "../../../common/DialogModal";
import SuccessModal from "../../../common/SuccessModal";
import Spinner from "../../../common/Spinner";
import {
  getGroupSims,
  removeSim,
} from "../../../app/actions/groupAction/groupAction";
import {
  hideModal,
  toggeleDeleteSim,
} from "../../../app/reducers/groupReducer/groupReducer";

const ViewGroup = () => {
  const { group, group_state, deleteSimState, deleteSim } = useSelector(
    (state) => state.group
  );

  const [deleteId, setDeleteId] = useState("");
  const successModal = deleteSimState === "sim_removed";

  const dispatch = useDispatch();

  const onCancelClickHandler = () => {
    dispatch(toggeleDeleteSim(false));
    setDeleteId("");
  };
  const onConfirmRemoveOrDeleteHandler = () => {
    console.log(deleteId);
    if (!deleteId) return;
    dispatch(removeSim(deleteId));
  };

  const onRemoveGroupHandler = (data) => {
    dispatch(setDeleteGroup(false));
    setDeleteId(data._id);
    console.log(data);
    dispatch(toggeleDeleteSim(true));
  };

  const onBackClickHandler = () => {
    dispatch(setViewGroupClicked(false));
  };

  const onModalHideHandler = () => {
    dispatch(getGroupSims(group.groupData._id));
    dispatch(hideModal());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <SuccessModal
        message={`SIM was successfully removed!`}
        show={successModal}
        onHideHandler={onModalHideHandler}
      />

      <DialogModal show={deleteSim}>
        <img src={warningInfo} alt="success logo" width="30%" />
        <div>
          You are about to remove this sim from {` ${group?.groupData?.name}`}!
        </div>

        <div className="continer-fluid mt-3 ml-3 mr-auto">
          <button
            className="mr-3 mb-3 btn btn-light text-danger text-white"
            style={{ borderColor: "red" }}
            onClick={onCancelClickHandler}
            disabled={deleteSimState === "remove_sim"}
          >
            <span>Cancel</span>
          </button>

          <button
            className="mr-3 mb-3 btn btn-danger text-white"
            onClick={onConfirmRemoveOrDeleteHandler}
            disabled={deleteSimState === "remove_sim"}
          >
            <span>
              {deleteSimState === "remove_sim" ? "Removing" : "Remove"}{" "}
            </span>
          </button>
        </div>
      </DialogModal>

      {group_state === "got_group" && group && (
        <div
          className="p-3 mb-1 bg-white text-dark rounded"
          style={{ height: "100%" }}
        >
          <div className="container-fluid">
            <div className="container-fluid mt-4">
              <div className="row">
                <h5 className="font-weight-bold mr-auto ml-3">
                  {group.groupData.name}
                </h5>
                <h5 className="ml-3 mr-3 text-success">
                  Total SIM Cards: {group.groupSims.length}
                </h5>
              </div>
              <small className="mr-auto">{group.groupData.description}</small>
            </div>

            {/*  */}

            <div className="container-fluid mt-5">
              <table className="table shadow-sm p-1 mb-1 bg-white text-dark rounded">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">NAME</th>
                    <th scope="col">ICCID</th>
                    <th scope="col">IMSI</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                {group.groupSims.map((datum) => (
                  <tbody key={datum.id}>
                    <tr>
                      <td></td>
                      {datum.status === "ACTIVE" && (
                        <td>
                          <img
                            className="rounded-circle"
                            src={active}
                            alt="company logo"
                            style={{ width: "20px", marginRight: "8px" }}
                            title="status"
                          />
                        </td>
                      )}
                      {datum.status === "INACTIVE" && (
                        <td>
                          <img
                            className="rounded-circle"
                            src={inactive}
                            alt="company logo"
                            style={{ width: "20px", marginRight: "8px" }}
                            title="status"
                          />
                        </td>
                      )}
                      {datum.status === "SUSPENDED" && (
                        <td>
                          <img
                            className="rounded-circle"
                            src={suspended}
                            alt="company logo"
                            style={{ width: "20px", marginRight: "8px" }}
                            title="status"
                          />
                        </td>
                      )}
                      <td>SIM 1</td>
                      <td>{datum.iccid}</td>
                      <td>{datum.imsiMsisdnPairs[0].imsi}</td>
                      <td>
                        <div
                          className="mr-3 btn text-danger button-hover"
                          style={{ borderColor: "red" }}
                          onClick={() => onRemoveGroupHandler(datum)}
                        >
                          <span>Remove</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>

            {/*  */}

            <div className="container-fluid">
              <div className="container-fluid">
                <div className="row">
                  <div className="mr-auto"></div>
                  <button
                    className="mb-3 mt-3 ml-auto btn btn-color text-light"
                    style={{ width: "10rem" }}
                    onClick={onBackClickHandler}
                  >
                    <span>Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {group_state === "getting_group" && <Spinner useSpinnarLarge={true} />}
    </motion.div>
  );
};

export default ViewGroup;
