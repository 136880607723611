import axios from "axios";

import { getStoredAuthToken, removeStoredAuthToken } from "./authToken";

const defaults = {
  baseURL: "https://api.connect.gentro.io",

  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

const api = axios.create({
  baseURL: defaults.baseURL,
});

api.interceptors.request.use(
  function (config) {
    if (getStoredAuthToken()) {
      config.headers.Authorization = `Bearer ${getStoredAuthToken()}`;
    }
    console.log(config, getStoredAuthToken());
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (error.response) {
      console.log(error.response);
      return new Promise((_, reject) => {
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("unauthenticated");
          removeStoredAuthToken();
          localStorage.clear();
          window.location.href = "/";
          // redirect to login
        } else {
          reject(error.response.data.error || error.response);
        }
      });
    } else {
      return new Promise((_, reject) => {
        reject(defaults.error);
      });
    }
  }
);
export default api;
