import { createSlice } from "@reduxjs/toolkit";
import { createUser, getAllUsers } from "../../actions/usersAction/usersAction";
const initialState = {
  allUsers: [],
  errorUsers: "",
  user_state: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    hideModal(state) {
      return {
        ...state,
        user_state: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => ({
      ...state,
      allUsers: action.payload,
      user_state: "",
    }));
    builder.addCase(getAllUsers.pending, (state, action) => ({
      ...state,
      user_state: "loading",
    }));
    builder.addCase(getAllUsers.rejected, (state, action) => ({
      ...state,
      user_state: "",
      error: action.error,
    }));
    builder.addCase(createUser.pending, (state, action) => {
      return {
        ...state,
        user_state: "creating",
      };
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      return {
        ...state,
        user_state: "created",
      };
    });
    builder.addCase(createUser.rejected, (state, action) => ({
      ...state,
      user_state: "",
      error: action.error,
    }));
  },
});

export const { hideModal } = userSlice.actions;

export default userSlice.reducer;
