import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  login,
  resetPassword,
  signUp,
} from "../../actions/authAction/authAction";

const initialState = {
  isAuthenticating: false,
  error: "",
  data: "",
  forgotPasswordState: "start",
  errorForgotPassword: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // for loading state
    builder.addCase(signUp.pending, (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticating: true,
        error: "",
      };
    });

    // for success state
    builder.addCase(signUp.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticating: false,
        data: action.payload,
        error: "",
      };
    });

    // for error state

    builder.addCase(signUp.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticating: false,
        data: "",
        error: action.error,
      };
    });

    builder.addCase(login.pending, (state, action) => {
      return {
        ...state,
        isAuthenticating: true,
        error: "",
      };
    });

    // for success state
    builder.addCase(login.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticating: false,
        data: action.payload,
        error: "",
      };
    });

    // for error state

    builder.addCase(login.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticating: false,
        data: "",
        error: action.error,
      };
    });

    // for success state
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        forgotPasswordState: "sent",

        errorForgotPassword: "",
      };
    });

    // for error state

    builder.addCase(forgotPassword.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        forgotPasswordState: "error",

        errorForgotPassword: action.error.message || action.error,
      };
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      return {
        ...state,
        forgotPasswordState: "sending",
        errorForgotPassword: "",
      };
    });

    // for success state
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        forgotPasswordState: "reset",

        errorForgotPassword: "",
      };
    });

    // for error state

    builder.addCase(resetPassword.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        forgotPasswordState: "resetError",

        errorForgotPassword: action.error.message || action.error,
      };
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      return {
        ...state,
        forgotPasswordState: "reseting",
        errorForgotPassword: "",
      };
    });
  },
});

export default authSlice.reducer;
