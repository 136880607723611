import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

const ModalAction = ({ show, title, body, buttonText, cancelText, onClickHandler, onCancelHandler, onHideHandler }) => {
    return (
        <div>
            <Modal show={show} onHide={onHideHandler} /** centered*/>
                <Modal.Body>{body}</Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalAction