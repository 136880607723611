import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FloatingLabelTextInput from "../common/FloatingLabelTextInput";
import logoGreen from "../assets/logo_green.png";
import verifyPhoneImage from "../assets/verify_phone.png";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Spinner from "../common/Spinner";
import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";

const PhoneVerification = () => {
  const [password, setPassword] = useState("");
  const [isDeactivated, setDeactivated] = useState(false);
  const [visibility, setVisibility] = useState("visible");

  const [isVerifyClicked, setIsVerifyClicked] = useState(false);
  const [pin, setPin] = useState("");

  const navigate = useNavigate();

  const forgotPasswordHandler = () => {};

  const resendHandler = () => {};

  const verifyHandler = () => {
    setIsVerifyClicked(true);
    setDeactivated(true);

    setTimeout(() => {
      setIsVerifyClicked(false);
      setDeactivated(false);

      navigate("/");
    }, 3000);
  };

  console.log(pin);

  const VerifyButton = () => {
    return (
      <div>
        {isVerifyClicked ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="btn btn-light btn-block text-light"
            disabled={isDeactivated}
            style={{ height: "3rem" }}
          >
            <Spinner />
          </motion.button>
        ) : (
          <button
            className="btn btn-color btn-block btn-control text-light mb-5"
            onClick={verifyHandler}
            style={{ height: "3rem" }}
          >
            Verify and Create Account
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (pin.length === 4) {
      setTimeout(() => {
        setVisibility("hidden");
      }, 2000);
    } else {
      setVisibility("visible");
    }
  }, [pin.length === 4]);

  const OtpValue = () => {
    return pin === "" ? (
      <div className="text-center mb-4" />
    ) : (
      <div className="text-center txt-color" style={{ visibility: visibility }}>
        <small>{pin}</small>
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="row">
        <div className="col-md-7 bg-login" id="mobile_only" />
        <div className="col-md-5 mt-5">
          <div className="text-left ml-3 mb-4">
            <img src={logoGreen} alt="logo" />
          </div>
          <div className="text-center">
            <h4>Verify Your Phone Number</h4>
          </div>
          <div className="text-center mb-2 txt-color">
            <small>Code has been sent to +2348093530000</small>
          </div>

          <div className="text-center mb-2">
            <img src={verifyPhoneImage} alt="logo" />
          </div>

          <div className="text-center mb-3">
            <small>Enter OTP here</small>
          </div>
          <div style={{ display: "grid" }}>
            <OTPInput
              className="ml-auto mr-auto mb-3"
              value={pin}
              onChange={setPin}
              OTPLength={4}
              otpType="number"
              inputStyles={{
                borderRadius: "5px",
                borderColor: "#106437",
                boxShadow: "none",
              }}
              autoFocus={true}
              disabled={false}
            />
          </div>

          <div className="text-center mb-3 text-dark">
            <span className="btn bg-white txt-color">
              Didn't receive any code?{" "}
            </span>
            <span className="btn bg-white txt-color" onClick={resendHandler}>
              RESEND
            </span>
          </div>
          <VerifyButton />
        </div>
      </div>
    </motion.div>
  );
};

export default PhoneVerification;
