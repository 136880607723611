import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const getAllUsers = createAsyncThunk(
  "users/get-all",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/users");
      console.log(response);
    } catch (error) {}
  }
);

export const createUser = createAsyncThunk(
  "users/create-user",
  async (data, thunkAPI) => {
    try {
      console.log(thunkAPI);
      const response = await api.post("/auth/signup", data);
      console.log(response);
      if (response.data && response.data.success) {
        return response.data.data;
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      console.log(error);
      throw new Error(error?.message || "Something went wrong");
    }
  }
);
