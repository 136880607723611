import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

const fetchData = async (url) => {
  try {
    const response = await api.get(url);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const removeSim = createAsyncThunk("group/remove-sim", async (simId) => {
  try {
    const response = await api.delete(`/groups/${simId}/removeSim`);
    if (response.data.success) {
      return;
    } else {
      throw new Error(response.data?.message || "Something went wrong");
    }
  } catch (error) {
    throw new Error(
      error.data.message ||
        error.message ||
        "Request unsuccessful, something went wrong"
    );
  }
});

export const createGroup = createAsyncThunk(
  "group/create-group",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/groups", data);
      console.log(response, data);
      if (response.data.success && response.data.data) {
        return response.data.data;
      } else
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);

export const editGroup = createAsyncThunk(
  "group/edit-group",
  async (data, thunkAPI) => {
    try {
      const response = await api.patch(`/groups/${data._id}`, data.payload);
      console.log(response, data);
      if (response.data.success && response.data.data) {
        return response.data.data;
      } else
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);

export const getGroups = createAsyncThunk("group/get-all", async () => {
  try {
    const response = await api.get("/groups");
    console.log(response);
    if (response.data.success && response.data.data) {
      return response.data.data;
    } else {
      throw new Error(response.data?.message || "Something went wrong");
    }
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "Request unsuccessful, something went wrong"
    );
  }
});
export const getGroupSims = createAsyncThunk(
  "group/get-a-group",
  async (id, thunkAPI) => {
    try {
      const groupSimsUrl = `/groups/${id}/sims`;
      const groupUrl = `/groups/${id}`;

      const [groupResponse, groupSimResponse] = await Promise.all([
        fetchData(groupUrl),
        fetchData(groupSimsUrl),
      ]);

      if (groupResponse.data.success && groupSimResponse.data.success) {
        const data = {
          groupData: groupResponse.data.data,
          groupSims: groupSimResponse.data.data,
        };

        return data;
      } else {
        throw new Error("Something went wrong, try again");
      }
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);
export const deleteGroup = createAsyncThunk(
  "group/delete-group",
  async (id, thunkAPI) => {
    try {
      const response = await api.delete(`/groups/${id}`);
      if (response.data.success) {
        return;
      } else {
        throw new Error(response.data?.message || "Something went wrong");
      }
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);

export const getMySim = createAsyncThunk("sim/get-my-sim", async () => {
  try {
    const response = await api.get(`/sim/mySim`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data?.message || "Something went wrong");
    }
  } catch (error) {
    throw new Error(
      error.data.message ||
        error.message ||
        "Request unsuccessful, something went wrong"
    );
  }
});

export const addSimToGroup = createAsyncThunk(
  "sim/add-sim-to-group",
  async (data) => {
    try {
      console.log(data.simIds);
      const requestData = {
        simIds: data.simsIds,
      };
      console.log(data.simsIds);
      const response = await api.post(
        `/groups/${data.groupId}/bulk`,
        data.simsIds
      );
      if (response.data.success && response.data.data) {
        return response.data.data;
      } else
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);
