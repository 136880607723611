export const header = {
  textAlign: "start",
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
};
export const container = {
  marginTop: "50px",
  padding: "40px",
  backgroundColor: "white",
};
export const textBig = {
  fontSize: "20px",
  marginRight: "20px",
};
export const containerHead = {};
export const head = {
  fontSize: "25px",
  display: "flex",
  alignItems: "center",
};
export const line = {
  flex: "1",
  width: "100%",
  backgroundColor: "#B5B5B5",
  height: "1px",
  marginLeft: "40px",
};
export const headContent = {
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "550",
};
export const headBody = {
  display: "flex",
  justifyContent: "space-between",
  marginInline: "auto",
  marginBlock: "20px",
  alignItems: "center",
  maxWidth: "1300px",
  width: "100%",
};
export const headText = {
  color: "#B5B5B5",
  marginRight: "10px",
};
export const headCap = {
  color: "#505050",
};
export const body = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: "40px",
  padding: "40px",
};
export const map = {
  width: "100%",
};
export const chart = { width: "100%", height: "95%" };
export const box = {
  backgroundColor: "#F9F9F9",
  borderRadius: "8px",
  padding: "30px",
  height: "300px",
  textAlign: "start",
};
export const boxHead = {
  color: "#009A49",
  fontSize: "14px",
  fontWeight: "600",
  marginBottom: "30px",
};
export const boxText = { color: "#B5B5B5", fontWeight: "500" };
export const boxContent = {
  width: "100%",
  maxWidth: "500px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  fontWeight: "550",
};
