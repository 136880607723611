import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import SelectListGroup from "../../../common/SelectListGroup";
import WillIncludeSidebar from "../../../common/WillIncludeSidebar";
import Navbar from "../../layout/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextArea from "../../../common/TextArea";
import backIcon from "../../../assets/back-icon.png";
import SuccessModal from "../../../common/SuccessModal";
import Spinner from "../../../common/Spinner";
import Loading from "../../../common/Loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSimItemDropdown } from "../../../features/appSlice";
import { useSelector } from "react-redux";
import { sendSMS } from "../../../app/actions/sendSMSAction/sendSMSAction";
import CheckBox from "../../../common/CheckBox";
import { resetState } from "../../../app/reducers/sendSMS/sendSMS";

const SendSMS = () => {
  const { windowDimension } = useSelector((state) => state.app.value);

  const dispatch = useDispatch();

  const [isDeactivated, setDeactivated] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.state);
  const sims = queryParams.get("sims");
  const { send_state } = useSelector((state) => state.sendSMS);
  const simsArray = sims ? sims.split(",") : [];
  const simsOptions = simsArray.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const smsOptions = [
    {
      label: "ICCID",
      value: "",
    },
    ...simsOptions,
  ];

  console.log(simsArray, sims);

  const [type, setType] = useState("");
  const [SMSTo, setSMSTo] = useState("");

  const [message, setMessage] = useState("");

  const [textSmsTabState, setTextSmsTabState] = useState("nav-link active");
  const [binaryTabState, setBinaryTabState] = useState("nav-link");

  const [textColor, setTextColor] = useState("#000000");
  const [binaryColor, setBinaryColor] = useState("#D1D1D1");

  const [successModal, showSuccessModal] = useState(false);
  const [isSmsSent, setSmsSent] = useState(false);

  const typeOptions = [
    { label: "Select Type", value: "null" },
    { label: "System", value: "system" },
    { label: "Platform", value: "platform" },
  ];

  //   const smsOptions = [
  //     { label: "ICCID Number", value: "null" },
  //     { label: "6575858488484747", value: "6575858488484747" },
  //     { label: "6575858488484748", value: "6575858488484747" },
  //     { label: "6575858488484749", value: "6575858488484747" },
  //     { label: "6575858488484744", value: "6575858488484747" },
  //     { label: "6575858488484743", value: "6575858488484747" },
  //   ];

  const onTextSmsClickedHandler = () => {
    setTextSmsTabState("nav-link active");
    setTextColor("#000000");

    setBinaryColor("#D1D1D1");
    setBinaryTabState("nav-link");
  };

  const onBinaryCLickHandler = () => {
    setBinaryTabState("nav-link active");
    setBinaryColor("#000000");

    setTextColor("#D1D1D1");
    setTextSmsTabState("nav-link");
  };

  const onValueChange = () => {};

  const navigate = useNavigate();

  const onCancelHandler = () => {
    navigate("/sim-cards");
    dispatch(setSimItemDropdown(true));
  };

  const onSendSMSHandler = () => {
    let payload;
    if (!message) return;
    if (simsOptions.length === 1) {
      payload = {
        message,
        iccid: "8935711005200746988",
      };
    } else {
      //   const dataToSend = ids.map((id) => ({ id, message }));
      const data = simsOptions.map((item) => ({ iccid: item.value, message }));
      payload = {
        data,
      };
    }
    console.log(payload);

    dispatch(sendSMS(payload));

    // setTimeout(() => {
    //   setSmsSent(false);
    //   showSuccessModal(true);
    // }, 3000);
  };

  const onModalHideHandler = () => {
    navigate("/sim-cards");
    dispatch(resetState());
    dispatch(setSimItemDropdown(true));
  };

  useEffect(() => {
    dispatch(setSimItemDropdown(false));
  }, []);
  console.log(send_state);
  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="px-5 py-2"
          >
            {/* {isSmsSent && <Loading />} */}

            <SuccessModal
              message="SMS Sent Successfully!"
              show={send_state === "sent"}
              onHideHandler={onModalHideHandler}
            />

            <div
              className="btn text-left"
              onClick={onCancelHandler}
              style={{ display: "flex" }}
            >
              <img className="mr-3" src={backIcon} alt="icon" width="15px" />
              <span>Send SMS</span>
              <span style={{ marginLeft: "10px" }}>{simsArray[0]}</span>
              {simsArray.length > 1 && <span>...</span>}
            </div>
            <div
              className="mb-1 mt-2 bg-white text-dark rounded"
              style={{ height: "100%" }}
            >
              <div className="container-fluid p-3">
                <div className="row" style={{ padding: "40px" }}>
                  <div className="col-md-5">
                    <div className="text-left">
                      <small>From Type</small>
                    </div>
                    <SelectListGroup
                      borderRadius="5px"
                      bootstrapClass="form-control bg-transparent text-dark"
                      placeholder="Allocate"
                      name="dataAllocation"
                      value={type}
                      onChange={(e) => {
                        if (e.target.value === "null") {
                          setType("Select Type");

                          return;
                        }

                        if (e.target.value === "System") setType("System");
                        if (e.target.value === "System") setType("System");
                      }}
                      options={typeOptions}
                      // info={answerInfo}
                      disabled={isDeactivated}
                    />
                  </div>

                  <div className="col-md-5">
                    <div className="text-left">
                      <small>SMS To</small>
                    </div>
                    <SelectListGroup
                      borderRadius="5px"
                      bootstrapClass="form-control bg-transparent text-dark"
                      placeholder="Allocate"
                      name="dataAllocation"
                      value={SMSTo}
                      onChange={(e) => {
                        if (e.target.value === "null") {
                          setSMSTo("ICCID Number");

                          return;
                        }

                        if (e.target.value === "6575858488484747")
                          setSMSTo("6575858488484747");
                      }}
                      options={smsOptions}
                      // info={answerInfo}
                      disabled={isDeactivated}
                    />{" "}
                    *
                  </div>
                </div>

                {/* Tabs */}
                <ul className="nav nav-tabs mb-3 mt-2" id="ex1" role="tablist">
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ marginLeft: "45px" }}
                  >
                    <Link
                      className={textSmsTabState}
                      style={{ color: textColor }}
                      id="ex1-tab-1"
                      data-mdb-toggle="tab"
                      to="#"
                      role="tab"
                      aria-controls="ex1-tabs-1"
                      aria-selected={false}
                      onClick={onTextSmsClickedHandler}
                    >
                      Text SMS
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className={binaryTabState}
                      style={{ color: binaryColor }}
                      id="ex1-tab-2"
                      data-mdb-toggle="tab"
                      to="#"
                      role="tab"
                      aria-controls="ex1-tabs-2"
                      aria-selected={true}
                      onClick={onBinaryCLickHandler}
                    >
                      Binary SMS
                    </Link>
                  </li>
                </ul>
                {/* Tabs-end */}
                <div style={{ padding: "40px" }}>
                  <div className="text-left mt-2">
                    <small>SMS</small>
                  </div>
                  <TextArea
                    className="form-control"
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div
                    className="text-left"
                    style={{ color: "#D1D1D1", lineHeight: "0px" }}
                  >
                    <small>(160 Maximum characters)</small>
                  </div>
                </div>

                <div className="row" style={{ padding: "40px" }}>
                  <div className="form-check mt-4 ml-3">
                    <input
                      className="form-check-input"
                      id="mtn1"
                      type="radio"
                      name="mtn"
                      // value={mtn}
                      onChange={onValueChange}
                    />
                    <label className="form-check-label" htmlFor="mtn1">
                      GSM-7 Encoding
                    </label>
                  </div>

                  <div className="form-check mt-4 ml-3">
                    <input
                      className="form-check-input"
                      id="mtn1"
                      type="radio"
                      name="mtn"
                      // value={mtn}
                      onChange={onValueChange}
                    />
                    <label className="form-check-label" htmlFor="mtn1">
                      UCS2 Encoding
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row mt-3 ml-auto">
                {send_state === "sending" ? (
                  <div
                    className=" text-white"
                    style={{ width: "100%", marginLeft: "90%" }}
                  >
                    {" "}
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {" "}
                    <div
                      className={
                        windowDimension.winWidth < 400
                          ? "mr-3 mb-3 btn button-style text-dark shadow-sm text-dark"
                          : "ml-auto mr-3 mb-3 btn button-style text-dark shadow-sm text-dark"
                      }
                      style={{ height: "3rem", borderRadius: "8px" }}
                      onClick={onCancelHandler}
                    >
                      <p className="mt-1">Cancel</p>
                    </div>
                    {/* <SendSMSButton /> */}
                    <button
                      className="mb-3 btn btn-color text-white"
                      style={{ height: "3rem", borderRadius: "8px" }}
                      onClick={onSendSMSHandler}
                    >
                      <p className="mt-1">Send SMS</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default SendSMS;
