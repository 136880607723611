export const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formateDate = (timestamp) => {
  const date = new Date(timestamp);
  const month = date.getMonth() + 1;

  return {
    date: `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`,
    month: months[month],
  };
};

export default formateDate;
