import { createSlice } from "@reduxjs/toolkit";
import { sendSMS } from "../../actions/sendSMSAction/sendSMSAction";

const initialState = {
  send_state: "",
  error: "",
  data: null,
};

const sendSMSReducer = createSlice({
  name: "sendSMS",
  initialState,
  reducers: {
    resetState: (state) => {
      state.send_state = "";
      state.error = "";
      state.data = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendSMS.pending, (state, action) => {
      return {
        ...state,
        send_state: "sending",
      };
    });
    builder.addCase(sendSMS.fulfilled, (state, action) => {
      return {
        ...state,
        send_state: "sent",
        data: action.payload,
      };
    });
    builder.addCase(sendSMS.rejected, (state, action) => {
      return {
        ...state,
        send_state: "",
        error: action.error,
      };
    });
  },
});

export const { resetState } = sendSMSReducer.actions;
export default sendSMSReducer.reducer;
