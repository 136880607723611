import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const createOrder = createAsyncThunk(
  "order/create",
  async (data, ThunkAPI) => {
    try {
      const response = await api.post("/order", data);
      console.log(response, ThunkAPI);
      if (response.data.success && response.data.data) {
        return response.data.data;
      } else {
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
      }
    } catch (error) {
      console.log(error);
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);
