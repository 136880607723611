import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import { storeAuthToken } from "../../../utils/authToken";

export const signUp = createAsyncThunk(
  "auth/signup",
  async (data, thunkAPI) => {
    try {
      console.log(thunkAPI);
      const response = await api.post("/auth/signup", data);
      console.log(response);
      if (response.data && response.data.success) {
        return response.data.data;
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      console.log(error);
      throw new Error(
        error.data?.message || error.message || "Something went wrong"
      );
    }
  }
);

export const login = createAsyncThunk("auth/signin", async (data, thunkAPI) => {
  try {
    console.log(thunkAPI);
    const response = await api.post("/auth/login", data);
    console.log(response);
    if (response.data && response.data.success) {
      storeAuthToken(response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));

      return response.data.data;
    } else {
      const errorMesage = response.data?.message;
      throw new Error(errorMesage ? errorMesage : "Something went wrong");
    }
  } catch (error) {
    console.error(error);
    console.log(error);
    throw new Error(error?.message || "Something went wrong");
  }
});

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/auth/forgetPassword", data);
      if (response.data && response.data.success) {
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      console.log(error.message, error.response);
      throw new Error(
        error.data?.message || error.message || "Something went wrong"
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/auth/changePassword", data);
      if (response.data && response.data.success) {
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      console.log(error);
      throw new Error(
        error.data?.message || error.message || "Something went wrong"
      );
    }
  }
);
