import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SelectListGroup from "../../common/SelectListGroup";
import {
  setManageSimSelected,
  setSimClicked,
  setSimInventorySelected,
  setSimItemDropdown,
  setSimPurchaseSelected,
} from "../../features/appSlice";
import planIcon from "../../assets/lora.png";
import dashboard from "../../assets/dashboard.png";
import simIcon from "../../assets/sim_card.png";
import walletIcon from "../../assets/wallet.png";
import wifi from "../../assets/wifi.png";
import user from "../../assets/user.png";
import group from "../../assets/group.png";
import settings from "../../assets/settings.png";
import logout from "../../assets/logout.png";
import { motion } from "framer-motion";
import { PREVIOUS_ROUTE } from "../../app/constants";
import { removeStoredAuthToken } from "../../utils/authToken";

const Sidebar = () => {
  const {
    dashItem,
    simItem,
    manageSimItem,
    purchaseSimItem,
    simInventoryItem,
    simPlansItem,
    loraItem,
    loraPlansItem,
    walletItem,
    userItem,
    groupItem,
    settingsItem,
    isSimClicked,
    isSimItemDropdown,
    windowDimension,
  } = useSelector((state) => state.app.value);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onCLickHandler = () => {
    navigate("/sim-cards");
    dispatch(setSimItemDropdown(true));

    localStorage.setItem(PREVIOUS_ROUTE, "/sim-cards");
  };

  const onSelectHandler = () => {
    dispatch(setSimItemDropdown(!isSimItemDropdown));
  };

  const onManageClickHandler = () => {
    dispatch(setManageSimSelected(true));
  };

  const onPurchaseClickHandler = () => {
    dispatch(setSimPurchaseSelected(true));
  };

  const onSimInventoryHandler = () => {
    dispatch(setSimInventorySelected(true));
  };
  const handleLogout = () => {
    removeStoredAuthToken();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-white elevation">
      <div
        className="shadow d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white "
        style={{ position: "sticky", top: "0px", height: "100vh" }}
      >
        <ul
          className="mt-5 nav nav-pills flex-column mb-sm-auto align-items-center align-items-sm-start mt-3"
          id="menu"
        >
          <li
            className="nav-item"
            style={{
              marginBottom: "3px",
              backgroundColor: dashItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
            }}
          >
            <Link
              to="/dashboard"
              className="nav-link align-middle px-2 text-dark scale-transition"
              onClick={() => localStorage.setItem(PREVIOUS_ROUTE, "/dashboard")}
            >
              <img className="mr-3" src={dashboard} alt="icon" width="20px" />
              <span className="mr-auto d-none d-sm-inline">Dashboard</span>
            </Link>
          </li>

          <li
            className="nav-item"
            style={{
              marginBottom: "3px",
              backgroundColor: simItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
            }}
          >
            {!isSimClicked && (
              <Link
                to="/sim-cards"
                className="nav-link align-middle px-2 text-dark scale-transition"
                onClick={onCLickHandler}
              >
                <img className="mr-3" src={simIcon} alt="icon" width="18px" />
                <span className="d-none d-sm-inline">SIMs</span>
                {!isSimItemDropdown && (
                  <i className="ml-4 px-3 bi bi-chevron-down d-none d-sm-inline"></i>
                )}
                {isSimItemDropdown && (
                  <i className="ml-4 px-3 bi bi-chevron-up d-none d-sm-inline"></i>
                )}
              </Link>
            )}

            {isSimClicked && (
              <Link
                to="/sim-cards"
                className="nav-link align-middle px-2 text-dark scale-transition"
                onClick={onSelectHandler}
              >
                <img className="mr-3" src={simIcon} alt="icon" width="18px" />
                <span className="d-none d-sm-inline">SIMs</span>
                {isSimItemDropdown && (
                  <i className="ml-4 px-3 bi bi-chevron-up d-none d-sm-inline"></i>
                )}
                {!isSimItemDropdown && (
                  <i className="ml-4 px-3 bi bi-chevron-down d-none d-sm-inline"></i>
                )}
              </Link>
            )}
          </li>

          {isSimClicked && (
            <>
              {isSimItemDropdown && (
                <motion.div
                  initial={{ height: "0" }}
                  animate={{ height: "9rem", transition: { duration: 0.5 } }}
                  exit={{ y: window.innerHeight }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1.5 } }}
                    exit={{ opacity: 0 }}
                  >
                    <li
                      style={{
                        marginBottom: "9px",
                        backgroundColor: manageSimItem,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Link
                        to="#"
                        className="nav-link px-2 text-dark scale-transition align-middle"
                        onClick={onManageClickHandler}
                      >
                        {windowDimension.winWidth < 400 && (
                          <>
                            <span className="text-dark">M</span>
                          </>
                        )}
                        <span className="ml-3 ms-1 d-none d-sm-inline">
                          Manage SIM
                        </span>
                      </Link>
                    </li>
                    <li
                      style={{
                        marginBottom: "9px",
                        backgroundColor: purchaseSimItem,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Link
                        to="#"
                        className="nav-link px-2 text-dark scale-transition align-middle"
                        onClick={onPurchaseClickHandler}
                      >
                        {windowDimension.winWidth < 400 && (
                          <>
                            <span className="text-dark">P</span>
                          </>
                        )}
                        <span className="ml-4 ms-1 d-none d-sm-inline">
                          Purchase SIM
                        </span>
                      </Link>
                    </li>
                    <li
                      style={{
                        marginBottom: "9px",
                        backgroundColor: simInventoryItem,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Link
                        to="#"
                        className="nav-link px-2 text-dark scale-transition align-middle mb-2"
                        onClick={onSimInventoryHandler}
                      >
                        {windowDimension.winWidth < 400 && (
                          <>
                            <span className="text-dark">S</span>
                          </>
                        )}
                        <span className="ml-4 ms-1 d-none d-sm-inline">
                          SIM Inventory
                        </span>
                      </Link>
                    </li>
                  </motion.div>
                </motion.div>
              )}
            </>
          )}

          {/* <li
            style={{
              marginBottom: "3px",
              backgroundColor: simPlansItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
            }}
          >
            <Link
              to="/sim-plans"
              className="nav-link px-2 text-dark scale-transition align-middle"
              onClick={() => localStorage.setItem(PREVIOUS_ROUTE, "/sim-plans")}
            >
              <img className="mr-3" src={planIcon} alt="icon" width="18px" />
              <span className="d-none d-sm-inline">SIM Plans</span>
            </Link>
          </li> */}
          {/* <li style={{ marginBottom: '3px', backgroundColor: loraItem, paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px' }}>
                        <Link
                            to='/lora'
                            className="nav-link px-2 text-dark scale-transition align-middle"
                            onClick={() => localStorage.setItem(PREVIOUS_ROUTE, '/lora')}
                        >
                            <img className='mr-3' src={wifi} alt='icon' width='18px' />
                            <span className="d-none d-sm-inline">LoRa</span>
                        </Link>
                    </li>
                    <li style={{ marginBottom: '3px', backgroundColor: loraPlansItem, paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px' }}>
                        <Link
                            to='/lora-plans'
                            className="nav-link px-2 text-dark scale-transition align-middle"
                            onClick={() => localStorage.setItem(PREVIOUS_ROUTE, '/lora-plans')}
                        >
                            <img className='mr-3' src={planIcon} alt='icon' width='18px' />
                            <span className="d-none d-sm-inline">LoRa Plans</span>
                        </Link>
                    </li> */}

          <li
            style={{
              marginBottom: "3px",
              backgroundColor: walletItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
            }}
          >
            <Link
              to="/billing"
              className="nav-link px-2 text-dark scale-transition align-middle"
              onClick={() => localStorage.setItem(PREVIOUS_ROUTE, "/wallet")}
            >
              <img className="mr-3" src={walletIcon} alt="icon" width="18px" />
              <span className="d-none d-sm-inline">Billing</span>
            </Link>
          </li>
          {/* <li style={{ marginBottom: '3px', backgroundColor: userItem, paddingLeft: '5px', paddingRight: '5px', borderRadius: '5px' }}>
                        <Link
                            to='/user'
                            className="nav-link px-2 text-dark scale-transition align-middle"
                            onClick={() => localStorage.setItem(PREVIOUS_ROUTE, '/user')}
                        >
                            <img className='mr-3' src={user} alt='icon' width='18px' />
                            <span className="d-none d-sm-inline">User</span>
                        </Link>
                    </li> */}
          <li
            style={{
              marginBottom: "3px",
              backgroundColor: groupItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
            }}
          >
            <Link
              to="/groups"
              className="nav-link px-2 text-dark scale-transition align-middle"
              onClick={() => localStorage.setItem(PREVIOUS_ROUTE, "/groups")}
            >
              <img className="mr-3" src={group} alt="icon" width="18px" />
              <span className="d-none d-sm-inline">Groups</span>
            </Link>
          </li>
          <li
            style={{
              backgroundColor: settingsItem,
              paddingLeft: "5px",
              paddingRight: "5px",
              borderRadius: "5px",
              height: "43px",
            }}
          >
            <Link
              to="/settings"
              className="nav-link px-2 text-dark scale-transition align-middle mb-5"
            >
              <img className="mr-3" src={settings} alt="icon" width="18px" />
              <span className="d-none d-sm-inline">Settings</span>
            </Link>
          </li>
        </ul>
        <hr />
        <div className="dropdown pb-4 mt-5">
          <div
            className="nav-link px-2 align-middle text-dark scale-transition"
            onClick={handleLogout}
          >
            <img className="mr-3" src={logout} alt="icon" width="18px" />
            <span className="d-none d-sm-inline">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
