import { motion } from "framer-motion";
import Navbar from "../layout/Navbar";
import WillIncludeSidebar from "../../common/WillIncludeSidebar";
import { useSelector } from "react-redux";
import ManageSIMs from "./simActivities/ManageSIMs";
import PurchaseSIMs from "./simActivities/PurchaseSIMs";
import { useEffect } from "react";
import {
  setManageSimItem,
  setManageSimSelected,
  setSimClicked,
  setSimItem,
} from "../../features/appSlice";
import { useDispatch } from "react-redux";
import SimInventory from "./simActivities/SimInventory";
import filter from "../../assets/filter.png";
import chevronUp from "../../assets/circle-chevron-up.png";
import chevronDown from "../../assets/circle-chevron-down.png";
import { useState } from "react";
import CheckBox from "../../common/CheckBox";
import SelectListGroup from "../../common/SelectListGroup";
import {
  filterSimByStatus,
  fiterSimByIccidAndImsi,
} from "../../app/reducers/simReducer/simReducer";

const Sims = () => {
  const { isManageSimSelected, isSimPurchaseSelected, isSimInventorySelected } =
    useSelector((state) => state.app.value);

  const dispatch = useDispatch();
  const [iccid, setIccid] = useState("");
  const [imsi, setImsi] = useState("");
  const [isStatusFilterDropdown, setStatusDropdown] = useState(false);
  const [isFIlterByDropdown, setFilterBYDropdown] = useState(false);

  const [isDeactivated, setDeactivated] = useState(false);
  const [plan, setPlan] = useState("");

  const [value, setValue] = useState("");

  const [status, setStatus] = useState({
    active: false,
    suspended: false,
    all: false,
    inactive: false,
  });

  const handleChangeStatus = (currStatus) => {
    setStatus((prev) => ({
      ...prev,
      [currStatus]: !status[currStatus],
    }));
  };

  const onStatusFIlterTapHandler = () => {
    setStatusDropdown(!isStatusFilterDropdown);
  };

  const onFilterByTapHandler = () => {
    setFilterBYDropdown(!isFIlterByDropdown);
  };

  const onCancelHandler = () => {
    setFilterBYDropdown(false);
  };

  const onApplyHandler = () => {
    dispatch(
      fiterSimByIccidAndImsi({
        iccid,
        imsi,
      })
    );
    setFilterBYDropdown(false);
  };

  useEffect(() => {
    dispatch(filterSimByStatus(status));
  }, [status]);

  const planOptions = [
    { label: "Choose Plan", value: "null" },
    { label: "Regular", value: "regular" },
    { label: "Premium", value: "premium" },
  ];

  useEffect(() => {
    dispatch(setSimClicked(true));
    dispatch(setManageSimSelected(true));

    dispatch(setSimItem());
    dispatch(setManageSimItem());
  }, []);

  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
          >
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="container-fluid">
                  <div
                    className="container-fluid"
                    style={{
                      visibility: isManageSimSelected ? "visible" : "hidden",
                    }}
                  >
                    <div className="row d-flex">
                      <div className="shadow-sm filterStatusButton mr-auto">
                        <div
                          className="container-fluid"
                          onClick={onStatusFIlterTapHandler}
                        >
                          <div className="row d-flex">
                            <div className="mt-1 ml-2">
                              <img src={filter} alt="icon" width="20px" />
                            </div>
                            <div className="mt-2 ml-2">Filter Status</div>
                            <div className="mt-1 ml-auto mr-2">
                              {isStatusFilterDropdown ? (
                                <img src={chevronUp} alt="icon" width="25px" />
                              ) : (
                                <img
                                  src={chevronDown}
                                  alt="icon"
                                  width="25px"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {isStatusFilterDropdown && (
                          <motion.div
                            initial={{ height: "0rem" }}
                            animate={{
                              height: "10rem",
                              transition: { duration: 0.5 },
                            }}
                            exit={{ y: window.innerHeight }}
                            className="shadow-sm filterStatusDropdown"
                          >
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{
                                opacity: 1,
                                transition: { duration: 1.5 },
                              }}
                              exit={{ opacity: 0 }}
                              className="container-fluid mt-2"
                            >
                              <div className="row d-flex">
                                <div className="mt-3 ml-5">
                                  <CheckBox
                                    id="allDevices"
                                    label="All Devices"
                                    value={status.all}
                                    onCheckedHandler={() =>
                                      handleChangeStatus("all")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row d-flex">
                                <div className="mt-2 ml-5">
                                  <CheckBox
                                    id="active"
                                    label="Active"
                                    value={status.active}
                                    onCheckedHandler={() => {
                                      handleChangeStatus("active");
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row d-flex">
                                <div className="mt-2 ml-5">
                                  <CheckBox
                                    id="inactive"
                                    label="Inactive"
                                    value={status.inactive}
                                    onCheckedHandler={() => {
                                      handleChangeStatus("inactive");
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row d-flex">
                                <div className="mt-2 ml-5">
                                  <CheckBox
                                    id="suspended"
                                    label="Suspended"
                                    value={status.suspended}
                                    onCheckedHandler={() => {
                                      handleChangeStatus("suspended");
                                    }}
                                  />
                                </div>
                              </div>
                            </motion.div>
                          </motion.div>
                        )}
                      </div>

                      <div className="shadow-sm filterBy ml-auto">
                        <div
                          className="container-fluid"
                          onClick={onFilterByTapHandler}
                        >
                          <div className="row d-flex">
                            <div className="mt-2 ml-2">Filter By</div>
                            <div className="mt-2 ml-auto mr-2">
                              {isFIlterByDropdown ? (
                                <img src={chevronUp} alt="icon" width="25px" />
                              ) : (
                                <img
                                  src={chevronDown}
                                  alt="icon"
                                  width="25px"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {isFIlterByDropdown && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{
                              height: "22rem",
                              transition: { duration: 0.5 },
                            }}
                            exit={{ y: window.innerHeight }}
                            className="shadow-sm filterByDropdown"
                          >
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{
                                opacity: 1,
                                transition: { duration: 1.5 },
                              }}
                              exit={{ opacity: 0 }}
                              className="container-fluid mt-2"
                            >
                              <div className="row d-flex m-auto">
                                <div className="text-left mt-3">ICCID</div>
                                <div className="input-group rounded">
                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    style={{
                                      height: "3rem",
                                      borderRadius: "10px 0px 0px 10px",
                                    }}
                                    value={iccid}
                                    onChange={(e) => setIccid(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-color-dark"
                                    style={{
                                      height: "3rem",
                                      borderRadius: "0px 10px 10px 0px",
                                    }}
                                  >
                                    <i className="fas fa-search"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="row d-flex m-auto">
                                <div className="text-left mt-3">IMSI</div>
                                <div className="input-group rounded">
                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search..."
                                    style={{
                                      height: "3rem",
                                      borderRadius: "10px 0px 0px 10px",
                                    }}
                                    value={imsi}
                                    onChange={(e) => setImsi(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-color-dark"
                                    style={{
                                      height: "3rem",
                                      borderRadius: "0px 10px 10px 0px",
                                    }}
                                  >
                                    <i className="fas fa-search"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="mt-3">
                                <div className="text-left">PLANS</div>
                                <SelectListGroup
                                  bootstrapClass="form-control bg-transparent text-dark"
                                  borderRadius="10px"
                                  placeholder="Plans"
                                  name="plans"
                                  value={plan}
                                  onChange={(e) => {
                                    if (e.target.value === "null") {
                                      setPlan("Choose Plan");

                                      return;
                                    }

                                    if (e.target.value === "regular")
                                      setPlan("Regular");
                                    if (e.target.value === "premium")
                                      setPlan("premium");
                                  }}
                                  options={planOptions}
                                  // info={answerInfo}
                                  disabled={isDeactivated}
                                />
                              </div>

                              <div className="row mr-auto">
                                <div className="ml-auto mt-3">
                                  <button
                                    className="mb-3 btn btn-color-light btn-control text-dark"
                                    onClick={onCancelHandler}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="ml-3 mt-3">
                                  <button
                                    className="mb-3 btn btn-color btn-control text-light"
                                    onClick={onApplyHandler}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </motion.div>
                          </motion.div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-left mt-5 ml-3 mb-5">
                  <h5 className="font-weight-bold" style={{ fontSize: "28px" }}>
                    SIMs
                  </h5>
                </div>
              </div>
            </div>

            {isManageSimSelected && <ManageSIMs />}
            {isSimPurchaseSelected && <PurchaseSIMs />}
            {isSimInventorySelected && <SimInventory />}
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default Sims;
