import { useDispatch, useSelector } from "react-redux";
import {
  getGroupSims,
  getGroups,
} from "../../../app/actions/groupAction/groupAction";
import { useEffect } from "react";
import Spinner from "../../../common/Spinner";
import { motion } from "framer-motion";
import { setViewGroupClicked } from "../../../features/appSlice";

const Groups = () => {
  const dispatch = useDispatch();
  const { groups, group_state, error } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  const onViewHandler = (id) => {
    dispatch(setViewGroupClicked(true));
    dispatch(getGroupSims(id));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="p-3 mb-1 bg-white text-dark rounded"
      style={{ height: "100%" }}
    >
      <div className="container-fluid">
        <div className="container-fluid">
          <h5 className="font-weight-bold">My Groups</h5>
        </div>

        <div className="container-fluid m-5 text-center">
          {group_state === "getting_groups" && (
            <Spinner useSpinnarLarge={true} />
          )}
          {error && (
            <div>{error.message || "Something went wrong, try again"}</div>
          )}
          {group_state === "got_groups" && groups.length === 0 && (
            <div>No group available, kindly create one</div>
          )}
          {group_state === "got_groups" && groups.length > 0 && (
            <div className="row">
              {groups.map((item) => (
                <div key={item._id} className="mb-2 mr-auto">
                  <div
                    className="shadow-sm py-3 mt-3 mb-1 bg-white text-dark form-control scale-transition"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      cursor: "pointer",
                    }}
                    onClick={() => onViewHandler(item._id)}
                  >
                    <h5 className="font-weight-bold mt-4">{item.name}</h5>
                    <div>
                      <small>{item.description}</small>
                    </div>
                    <div className="mt-3">
                      {/* <small className="text-success">
                          <small>
                            TOTAL SIM CARDS: {item.numberOfsims}
                          </small>
                        </small> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Groups;
