import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const sendSMS = createAsyncThunk("sms/send", async (data) => {
  try {
    const url = data.iccid ? "/sms" : "/sms/bulk";
    const response = await api.post(url, data);
    console.log(response);
    if (response.data.success && response.data.data) {
      return response.data.data;
    } else {
      throw new Error(
        response.data.message || "Request unsuccessful, something went wrong"
      );
    }
  } catch (error) {
    throw new Error(
      error.data?.message || error.message || "Request unsuccessful"
    );
  }
});
