import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const getAllSims = createAsyncThunk("sim/get-all", async (params) => {
  try {
    const response = await api.get("/sim/mySim", { params });
    console.log(response);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "Something went wrong");
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.data.message || "Something went wrong");
  }
});

export const getSim = createAsyncThunk("sim/get-sim", async (data) => {
  try {
    console.log(data);
    const params = {
      page: 1,
      limit: 15,
    };
    const response = await api.get(`/sim/${data.id}`, {
      params,
    });
    console.log(response);
    if (response.data.success && response.data.data) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "Something went wrong");
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.data.message || "Something went wrong");
  }
});