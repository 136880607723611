import { createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  editProfile,
  getUserProfile,
} from "../../actions/settingsAction/settingsAction";

const initialState = {
  user: "",
  loading_state: "",
  error: "",
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    hideModal: (state) => {
      state.user_state = "";
      state.loading_state = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state, action) => {
      return {
        ...state,
        loading_state: "getting_user",
      };
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      return {
        ...state,
        loading_state: "got_user",
        user: action.payload,
      };
    });
    builder.addCase(getUserProfile.rejected, (state, action) => {
      return {
        ...state,
        loading_state: "",
        error: action.error,
      };
    });
    builder.addCase(changePassword.pending, (state, action) => {
      return { ...state, loading_state: "changing_password" };
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      return { ...state, loading_state: "password_changed" };
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      return { ...state, loading_state: "" };
    });
    builder.addCase(editProfile.pending, (state, action) => {
      return {
        ...state,
        loading_state: "changing_business",
      };
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      return {
        ...state,
        loading_state: "business_changed",
      };
    });
    builder.addCase(editProfile.rejected, (state, action) => {
      return {
        ...state,
        loading_state: "",
      };
    });
  },
});

export const { hideModal } = settingSlice.actions;

export default settingSlice.reducer;
