import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import SelectListGroup from "../../../common/SelectListGroup";
import { useDispatch } from "react-redux";
import useNetworkStatus from "../../../common/NetworkHook";
import Spinner from "../../../common/Spinner";
import SuccessModal from "../../../common/SuccessModal";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { PREVIOUS_ROUTE } from "../../../app/constants";
import { setSimItemDropdown } from "../../../features/appSlice";

const PersonalProfile = ({ user }) => {
  const { isOnline } = useNetworkStatus();

  console.log(isOnline, user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDeactivated = true;

  const [selectedLanguage, setLanguage] = useState("");
  const [isChangesSaved, setSavedChanges] = useState(false);
  const [successModal, showSuccessModal] = useState(false);

  const languageOptions = [
    { label: "Select Language", value: "null" },
    { label: "English", value: "English" },
    { label: "French", value: "French" },
    { label: "Dutch", value: "Dutch" },
  ];

  const onCancelHandler = () => {
    if (isDeactivated) return;
    if (localStorage.getItem(PREVIOUS_ROUTE) == "/sim-cards")
      dispatch(setSimItemDropdown(true));
    navigate(localStorage.getItem(PREVIOUS_ROUTE));
  };

  const onSaveChangesHandler = () => {
    setSavedChanges(true);

    setTimeout(() => {
      showSuccessModal(true);
      setSavedChanges(false);
    }, 3000);
  };

  const onModalHideHandler = () => {
    showSuccessModal(false);
    setTimeout(() => navigate(localStorage.getItem(PREVIOUS_ROUTE)), 1000);
  };

  //   const SaveChangesButton = () => {
  //     return (
  //       <>
  //         {isChangesSaved ? (
  //           <motion.button
  //             initial={{ opacity: 0 }}
  //             animate={{ opacity: 1, transition: { duration: 1 } }}
  //             exit={{ opacity: 0 }}
  //             className="col-md-5 ml-auto mb-3 btn btn-light text-white"
  //             disabled={isDeactivated}
  //             style={{ height: "3rem", borderRadius: "10px" }}
  //           >
  //             <Spinner />
  //           </motion.button>
  //         ) : (
  //           <div
  //             className="col-md-5 ml-auto mb-3 btn btn-color text-white"
  //             style={{ height: "3rem", borderRadius: "10px" }}
  //             onClick={onSaveChangesHandler}
  //           >
  //             <p className="mt-1">Save Changes</p>
  //           </div>
  //         )}
  //       </>
  //     );
  //   };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <SuccessModal
        message="Changes saved successfully!"
        show={successModal}
        onHideHandler={onModalHideHandler}
      />

      <div className="container-fluid">
        <div
          className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-3"
          style={{ height: "100%" }}
        >
          <div className="text-left mt-3 ml-3">
            <h5 className="font-weight-bold" style={{ fontSize: "20px" }}>
              Edit Personal Profile
            </h5>
          </div>

          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Name</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={`${user.firstName} ${user.middleName} ${user.lastName}`}
                  style={{ height: "3rem", borderRadius: "10px" }}
                  disabled={isDeactivated}
                />
              </div>

              <div className="text-left mt-3">
                <small>Email</small>
              </div>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={user.email}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />
            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Address</small>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address"
                value={user.address}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />

              <div className="text-left mt-3">
                <small>Phone Number</small>
              </div>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  height: "3rem",
                  borderRadius: "10px",
                }}
                country={"ng"}
                value={user.phoneNumber}
                enableSearch={true}
                disabled={isDeactivated}
              />

              <div style={{ visibility: "hidden" }}>
                <div className="text-left mt-5">
                  <small>Hidden</small>
                </div>
                <div className="input-group rounded" />
              </div>

              {/* <div className="container-fluid">
                <div
                  className="row mt-[0.5rem] flex justify-end	 items-end"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    className="col-md-5  mb-3 btn button-style text-dark"
                    style={{ height: "3rem", borderRadius: "10px" }}
                    onClick={onCancelHandler}
                  >
                    <p
                      className="mt-1"
                      style={{ cursor: isDeactivated && "wait" }}
                    >
                      Back
                    </p>
                  </div>

                  {/* {isOnline ? (
                    <SaveChangesButton />
                  ) : (
                    <div
                      className="col-md-5 ml-auto mb-3 btn button-style text-dark"
                      style={{ height: "3rem", borderRadius: "10px" }}
                    >
                      <p className="mt-1">Network Error</p>
                    </div>
                  )} */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PersonalProfile;
