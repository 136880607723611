import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const getUserProfile = createAsyncThunk(
  "settings/get-profile",
  async (_, thunkAPI) => {
    try {
      const response = await api.get("/user");
      console.log(response);
      if (response.data.data && response.data.success) {
        const user = JSON.parse(localStorage.getItem("user"));
        const storeUser = {
          ...user,
          companyDetail: response.data.data.companyDetail,
        };
        localStorage.setItem("user", JSON.stringify(storeUser));
        return response.data.data;
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }

      // if(response)
    } catch (error) {
      console.error(error);
      console.log(error);
      throw new Error(
        error.data?.message || error?.message || "Something went wrong"
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "settings/change-password",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/auth/updatePassword", data);
      console.log(response);
      if (
        response.data.message === "Password Change successful" &&
        response.data.success
      ) {
        return response.data.data;
      } else {
        const errorMesage = response.data?.message;
        throw new Error(errorMesage ? errorMesage : "Something went wrong");
      }
    } catch (error) {
      throw new Error(
        error.data?.message || error?.message || "Something went wrong"
      );
    }
  }
);

export const editProfile = createAsyncThunk(
  "settings/change-profile",
  async (data) => {
    try {
      const response = await api.patch("/user", data);
      console.log(response);
      if (response.status === 200 && response.data) {
        const user = JSON.parse(localStorage.getItem("user"));
        const storeUser = {
          ...user,
          companyDetail: response.data._doc.companyDetail,
        };
        localStorage.setItem("user", JSON.stringify(storeUser));

        return response.data._doc;
      } else
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
    } catch (error) {
      throw new Error(
        error.data.message ||
          error.message ||
          "Request unsuccessful, something went wrong"
      );
    }
  }
);
