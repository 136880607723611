import React from 'react'
import classnames from 'classnames'

const TextArea = ({
    name,
    placeholder,
    className,
    value,
    refInput,
    error,
    info,
    onChange,
    disabled
}) => {
    return (
        <div className="form-group">
            <textarea 
                name={name}
                className={classnames(className, { "is-invalid": error })}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={refInput}
                maxLength={160}
                rows={4}
                disabled={disabled}
            />
            {info && <small className='form-text text-muted'>{info}</small>}
            {error && <div className='invalid-feedback'>{error}</div>}
        </div>
    )
}

export default TextArea