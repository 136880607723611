import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import connectLogo from "./../../../assets/Green version.png";
// Register fonts
Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v16/4UaGrENHsxQIBN_h2xShKg.ttf",
    }, // Regular
    {
      src: "https://fonts.gstatic.com/s/helvetica/v16/4UaGrENHsxQIBN_h2xShKgt8.ttf",
      fontWeight: "bold",
    }, // Bold
  ],
});

// Define the styles
const styles = StyleSheet.create({
  body: {
    padding: 30,
    fontFamily: "Helvetica",
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
    fontWeight: "bold",
  },
  logoContainer: {
    marginBottom: 30,
  },
  logo: {
    width: 100,
    height: "auto",
  },
  orderSummary: {
    marginBottom: 20,
  },
  orderDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
  productTable: {
    width: "100%",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeader: {
    fontWeight: "bold",
  },
  tableCol: {
    width: "50%",
    padding: 8,
    fontSize: 12,
    textAlign: "center",
  },
  tableColRight: {
    width: "33%",
    padding: 8,
    fontSize: 12,
    textAlign: "right",
  },
  tableColCenter: {
    width: "33%",
    padding: 8,
    fontSize: 12,
    textAlign: "center",
  },
  total: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "right",
    marginTop: 10,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#0066FF",
    color: "white",
    padding: 10,
    textAlign: "center",
    borderRadius: 5,
    fontSize: 14,
  },
  footer: {
    marginTop: 30,
    fontSize: 12,
    color: "#888888",
    textAlign: "center",
  },
  link: {
    color: "#0066FF",
    textDecoration: "none",
  },
  tableColHead: {
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
  },
  text: {
    fontSize: 14,
  },
  textBold: {
    fontSize: 14,
    fontWeight: "bold",
  },
  borderBottom: {
    borderBottomWidth: 0.5,
    borderColor: "#e0e0e0",
  },
  page: {
    padding: 30,
  },
  section: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  headerPage2: {
    fontSize: 14,
    marginBottom: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000",
  },
  tableRowPage2: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "33.33%",
    borderStyle: "solid",

    borderWidth: 0.5,
    borderColor: "#000",
    backgroundColor: "#d3d3d3",
    padding: 5,
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableColPage2: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000",
    padding: 5,
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  tableCell: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 5,
    fontSize: 10,
    textAlign: "center",
  },
  noBorderBetweenHeaders: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
});

const InvoicePdf = ({ data, name }) => {
  const { smsAndDataUsage, amount } = data;
  const totalDataUsage = smsAndDataUsage.reduce(
    (total, item) => total + item.totalDataUsage,
    0
  );
  const totalSmsUsage = smsAndDataUsage.reduce(
    (total, item) => total + item.totalSmsUsage,
    0
  );
  const smsAndDataUsageNotEqualZero = smsAndDataUsage?.filter(
    (item) => item.totalDataUsage !== 0
  );
  console.log(totalDataUsage);
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            src={
              "https://h0k4.c16.e2-5.dev/gentro-public/gentro_connect_logo.png"
            }
          />
        </View>
        <View style={styles.header}>
          <Text style={styles.header}>Hi {name},</Text>
          <Text style={styles.header}>thanks for your business</Text>
        </View>
        <View style={styles.orderSummary}>
          <Text style={styles.orderDetails}>
            Your invoice for the month of {data.month}/{data.year} has been
            generated succesfully, also find in the next page the data and SMS
            consumption for each SIM
          </Text>
        </View>
        <View style={styles.productTable}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableColHead}>Total Data Consumption </Text>
              <Text style={styles.tableText}>
                {(totalDataUsage / 1004).toFixed(2)}MB
              </Text>
            </View>
            <Text style={styles.tableColRight}>
              {smsAndDataUsageNotEqualZero.length} SIMS
            </Text>
          </View>
          <View style={[styles.tableRow, styles.borderBottom]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableColHead}>Total SMS Consumption </Text>
              <Text style={styles.tableText}>{totalSmsUsage} units</Text>
            </View>
            <Text style={styles.tableColRight}>
              {smsAndDataUsageNotEqualZero.length} SIMS
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Subtotal</Text>
            <Text style={styles.tableColRight}>${amount.toFixed(2)}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Savings</Text>
            <Text style={styles.tableColRight}>$0.00</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Tax</Text>
            <Text style={styles.tableColRight}>$0.00</Text>
          </View>
        </View>
        <Text style={styles.total}>Total: ${amount.toFixed(2)}</Text>
        <View style={styles.buttonContainer}>
          <Text style={styles.text}>It's pleasure to serve you</Text>
        </View>
        <View>
          <Text style={styles.text}>Regards, </Text>
          <Text style={styles.textBold}>Gentro Team </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <Link src="https://twitter.com" style={{ marginHorizontal: 5 }}>
              <Text>🐦</Text>
            </Link>
            <Link src="https://facebook.com" style={{ marginHorizontal: 5 }}>
              <Text>📘</Text>
            </Link>
            <Link src="https://instagram.com" style={{ marginHorizontal: 5 }}>
              <Text>📸</Text>
            </Link>
          </View>
        </View>
        <Text style={styles.footer}>
          Have questions or need help? Email us at{" "}
          <Link style={styles.link} src="mailto:hello@gentro.io">
            hello@gentro.io
          </Link>
        </Text>
        <Text style={styles.footer}>
          Suite 24, Aisha Plaza Idimu Lagos, Nigeria © 2024 [Gentro Limited]
        </Text>
      </Page>
      <Page>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRowPage2}>
              <View
                style={[styles.tableColHeader, styles.noBorderBetweenHeaders]}
              >
                <Text style={styles.tableCell}>ICCID</Text>
              </View>
              <View
                style={[styles.tableColHeader, styles.noBorderBetweenHeaders]}
              >
                <Text style={styles.tableCell}> Data Cost(kb)</Text>
              </View>
              <View
                style={[styles.tableColHeader, styles.noBorderBetweenHeaders]}
              >
                <Text style={styles.tableCell}> SMS Cost</Text>
              </View>
            </View>
            {smsAndDataUsageNotEqualZero.map((item, index) => (
              <View style={styles.tableRowPage2} key={index}>
                <View style={styles.tableColPage2}>
                  <Text style={styles.tableCell}>{item.iccid}</Text>
                </View>
                <View style={styles.tableColPage2}>
                  <Text style={styles.tableCell}>{item.totalDataUsage}</Text>
                </View>
                <View style={styles.tableColPage2}>
                  <Text style={styles.tableCell}>{item.totalSmsUsage}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
