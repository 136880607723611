import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import SelectListGroup from "../../../common/SelectListGroup";
import { useDispatch, useSelector } from "react-redux";
import useNetworkStatus from "../../../common/NetworkHook";
import Spinner from "../../../common/Spinner";
import SuccessModal from "../../../common/SuccessModal";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { PREVIOUS_ROUTE } from "../../../app/constants";
import noImg from "./../../../assets/no_image.png";
import { setSimItemDropdown } from "../../../features/appSlice";
import { editProfile } from "../../../app/actions/settingsAction/settingsAction";
import { hideModal } from "../../../app/reducers/settingsReducer/settingsReducer";

const BusinessProfile = () => {
  const { isOnline } = useNetworkStatus();
  const { loading_state, user } = useSelector((state) => state.settings);

  console.log(isOnline);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDeactivated, setDeactivated] = useState(false);
  const [phone, setPhone] = useState(user?.companyDetail?.companyPhone || "");
  const [address, setAddress] = useState(
    user?.companyDetail?.companyAddress || ""
  );
  const [email, setEmail] = useState(user?.companyDetail?.companyEmail || "");
  const [name, setName] = useState(user?.companyDetail?.companyName || "");
  const [selectedLanguage, setLanguage] = useState("");
  // State to store the uploaded image
  const [image, setImage] = useState(user?.companyDetail?.companyLogo || noImg);

  // Function to handle file input change
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
      // Pass the uploaded image to the parent component
      // onUpload(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const successModal = loading_state === "business_changed";
  const languageOptions = [
    { label: "Select Language", value: "null" },
    { label: "English", value: "English" },
    { label: "French", value: "French" },
    { label: "Dutch", value: "Dutch" },
  ];

  const onCancelHandler = () => {
    if (isDeactivated) return;
  };

  const onSaveChangesHandler = () => {
    const payload = {
      companyDetail: {
        companyName: name,
        companyAddress: address,
        companyLogo: image,
        companyEmail: email,
        companyPhone: phone,
      },
    };
    dispatch(editProfile(payload));
  };

  const onModalHideHandler = () => {
    dispatch(hideModal());
  };

  const SaveChangesButton = () => {
    return (
      <>
        {loading_state === "changing_business" ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="col-md-5 ml-auto mb-3 btn btn-light text-white"
            disabled={isDeactivated}
            style={{ height: "3rem", borderRadius: "10px" }}
          >
            <Spinner />
          </motion.button>
        ) : (
          <div
            className="col-md-5 ml-auto mb-3 btn btn-color text-white"
            style={{ height: "3rem", borderRadius: "10px" }}
            onClick={onSaveChangesHandler}
          >
            <p className="mt-1">Save Changes</p>
          </div>
        )}
      </>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <SuccessModal
        message="Changes saved successfully!"
        show={successModal}
        onHideHandler={onModalHideHandler}
      />

      <div className="container-fluid">
        <div
          className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-3"
          style={{ height: "100%" }}
        >
          <div className="text-left mt-3 ml-3">
            <h5 className="font-weight-bold" style={{ fontSize: "20px" }}>
              Edit Business Profile
            </h5>
          </div>
          <div
            style={{
              position: "relative",
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              overflow: "hidden",
              marginInline: "auto",
              marginBlock: "20px",
            }}
          >
            {/* Render the uploaded image or default profile picture */}
            <img
              src={image}
              alt="Profile"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            {/* Label for the file input */}
            <label
              htmlFor="fileInput"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
            {/* Input field to upload new profile picture */}
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="row mr-auto ml-auto">
            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Name</small>
              </div>
              <div className="input-group rounded">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ height: "3rem", borderRadius: "10px" }}
                  disabled={isDeactivated}
                />
              </div>

              <div className="text-left mt-3">
                <small>Email</small>
              </div>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />
            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className="text-left mt-4">
                <small>Address</small>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                style={{ height: "3rem", borderRadius: "10px" }}
                disabled={isDeactivated}
              />

              <div className="text-left mt-3">
                <small>Phone Number</small>
              </div>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  height: "3rem",
                  borderRadius: "10px",
                }}
                country={"ng"}
                value={phone}
                onChange={setPhone}
                enableSearch={true}
                disabled={isDeactivated}
              />

              <div style={{ visibility: "hidden" }}>
                <div className="text-left mt-5">
                  <small>Hidden</small>
                </div>
                <div className="input-group rounded" />
              </div>

              <div className="container-fluid">
                <div className="row mt-5">
                  {isOnline ? (
                    <SaveChangesButton />
                  ) : (
                    <div
                      className="col-md-5 ml-auto mb-3 btn button-style text-dark"
                      style={{ height: "3rem", borderRadius: "10px" }}
                    >
                      <p className="mt-1">Network Error</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default BusinessProfile;
