import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getDimensions, setNetworkStatus } from '../../features/appSlice'

const ScreenDimensions = ({ children }) => {

    const {windowDimension, networkStatus} = useSelector(state => state.app.value)
    
    console.log(windowDimension)

    const dispatch = useDispatch()

    const setDimensions = () => {
        dispatch(getDimensions({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        }))
    }
    
    useEffect(() => {
        dispatch(setNetworkStatus(navigator.onLine))
        
        window.addEventListener('resize', setDimensions)
        
        return () => {
            window.removeEventListener('resize', setDimensions)
        }
    }, [windowDimension])

    return (
        <div>{children}</div>
    )
}

export default ScreenDimensions