import { createSlice } from "@reduxjs/toolkit";
import { getAllOrders } from "../../actions/inventoryAction/inventoryAction";

const initialState = {
  order_state: "",
  error: "",
  data: null,
};

const inventoryReducer = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => {
      return {
        ...state,
        order_state: "getting",
        error: "",
      };
    });
    builder.addCase(getAllOrders.fulfilled, (state, action) => {
      return {
        ...state,
        order_state: "gotten",
        data: action.payload,
      };
    });
    builder.addCase(getAllOrders.rejected, (state, action) => {
      return {
        ...state,
        order_state: "error",
        error: action.error,
      };
    });
  },
});

export default inventoryReducer.reducer;
