import React from 'react'

const RadioButton = ({ id, value, label, onChangeHandler, checked }) => {
    return (

        <div className='custom-control custom-radio'>
            
            <input
                type="radio"
                className='custom-control-input'
                name={id}
                value={value}
                onChange={onChangeHandler}
                id={id}
                checked={checked}
            />
            <label className="custom-control-label" htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioButton