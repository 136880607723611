import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo_green.png";
import noImage from "../../assets/no_image.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setProfileDropdown } from "../../features/appSlice";

import editProfile from "../../assets/edit-profile.png";
import logout from "../../assets/logout.png";
import { removeStoredAuthToken } from "../../utils/authToken";
import { getUser } from "../../utils/localStorage";

const Navbar = () => {
  const isProfileDropdown = useSelector(
    (state) => state.app.value.isProfileDropdown
  );
  const dispatch = useDispatch();

  const { companyName, firstName, lastName, email, companyDetail } = getUser();

  let name, companylogo;
  if (companyDetail) {
    if (companyDetail.companyName) {
      name = companyDetail.companyName;
    }
    if (companyDetail.companyLogo) {
      companylogo =
        companyDetail.companyLogo === "testlogo.com"
          ? null
          : companyDetail.companyLogo;
    }
  }

  const onCancelHandler = () => {};
  const onEditHandler = () => {};
  const handleLogout = () => {
    removeStoredAuthToken();
    localStorage.clear();
    window.location.reload();
  };

  const onProfileClickHandler = () => {
    dispatch(setProfileDropdown(!isProfileDropdown));
  };

  return (
    <nav className="sticky-top navbar navbar-expand-sm navbar-dark bg-white elevation">
      <div className="container-fluid">
        <Link className="navbar-brand" to={"/dashboard"} onClick={() => {}}>
          <img src={logo} alt="connect" className="logo" />
        </Link>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-dark mr-4"
              to="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="rounded-circle"
                src={companylogo || noImage}
                alt="profile "
                style={{ width: "30px", marginRight: "8px" }}
                title="Profile Image"
              />
              {name || companyName || "Null"}
            </Link>
            <div
              className="dropdown-menu px-3"
              aria-labelledby="navbarDropdown"
              style={{ width: "110%" }}
            >
              <div className="row">
                <div className="mr-auto ml-3">
                  <img
                    className="rounded-circle mt-3"
                    src={companylogo || noImage}
                    alt="profile image"
                    style={{ width: "30px", marginRight: "8px" }}
                    title="Profile Image"
                  />
                </div>
                <i
                  className="mt-3 btn bi bi-x-lg d-none d-sm-inline text-light mr-3"
                  style={{ border: "0px", backgroundColor: "#DD9696" }}
                  onClick={onCancelHandler}
                ></i>
              </div>

              <div className="text-left mt-2">
                {firstName} {lastName}
              </div>
              <div className="text-left mt-2 text-truncate">{email}</div>

              <hr />

              <div className="row btn mr-2" onClick={onEditHandler}>
                <img
                  className="rounded-circle"
                  src={editProfile}
                  alt="edit"
                  style={{ width: "30px", marginRight: "8px" }}
                  title="Edit Profile"
                />
                Edit Profile
              </div>

              <div className="row btn" onClick={handleLogout}>
                <img
                  className="rounded-circle"
                  src={logout}
                  alt="logout"
                  style={{ width: "30px", marginRight: "8px" }}
                  title="Logout"
                />
                Logout
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
