import React from "react";
import { motion } from "framer-motion";
import { purchasedSimData } from "../../data/dataJson";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSimInventoryItem } from "../../../features/appSlice";
import { useState } from "react";
import simInventory from "../../../assets/sim-inventory.png";
import Spinner from "../../../common/Spinner";
import { getAllOrders } from "../../../app/actions/inventoryAction/inventoryAction";
import formateDate from "../../../utils/formatDate";
import Pagination from "../../../common/Pagination";

const SimInventory = () => {
  const dispatch = useDispatch();
  const { stat } = JSON.parse(localStorage.getItem("user"));
  const { data, order_state, error } = useSelector((state) => state.inventory);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handleChange = (page, limit) => {
    dispatch(
      getAllOrders({
        page,
        limit,
      })
    );
  };

  useEffect(() => {
    dispatch(setSimInventoryItem());
    dispatch(
      getAllOrders({
        page: 1,
        limit: 10,
      })
    );
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="container-fluid">
        <div className="container-fluid">
          <div
            className="p-1 mb-1 bg-light text-dark rounded mt-5"
            style={{ height: "3rem" }}
          >
            <div className="row">
              <button className="ml-3 mr-5 btn btn-color btn-control text-light">
                <img src={simInventory} alt="icon" width="27px" />
                <span>SIM INVENTORY</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container-fluid">
          <div
            className="shadow-sm p-1 mb-1 mt-3 bg-white text-dark rounded mt-1"
            style={{ height: "100%" }}
          >
            <div className="row mr-auto ml-auto mt-3">
              <div className="container-fluid px-5 ml-3">
                <div className="text-left mt-2 mb-3">
                  <h5 className="font-weight-bold">SIM Inventory Summary</h5>
                </div>
                <div className="container-fluid">
                  <div className="row mt-5 mb-4 text-center">
                    <div className="mr-auto">
                      <div
                        className="shadow-sm p-3 mb-3 bg-white rounded"
                        style={{ height: "6rem", width: "11rem" }}
                      >
                        <h5>
                          <span
                            className="col-md-12 font-weight-bold"
                            style={{ fontSize: "38px" }}
                          >
                            {stat.totalSim}
                          </span>
                          <p style={{ fontSize: "16px" }}>Total Gentro SIMs</p>
                        </h5>
                      </div>
                    </div>

                    <div className="mr-auto">
                      <div
                        className="shadow-sm p-3 mb-3 bg-white text-success rounded"
                        style={{ height: "6rem", width: "11rem" }}
                      >
                        <h5>
                          <span
                            className="col-md-12 font-weight-bold"
                            style={{ fontSize: "38px" }}
                          >
                            {stat.activeSim}
                          </span>
                          <p style={{ fontSize: "16px" }}>Active SIMs </p>
                        </h5>
                      </div>
                    </div>

                    <div className="mr-auto">
                      <div
                        className="shadow-sm p-3 mb-3 bg-white text-muted rounded"
                        style={{ height: "6rem", width: "11rem" }}
                      >
                        <h5>
                          <span
                            className="col-md-12 font-weight-bold"
                            style={{ fontSize: "38px" }}
                          >
                            {stat.inactiveSim}
                          </span>
                          <p style={{ fontSize: "16px" }}>Inactive SIMs </p>
                        </h5>
                      </div>
                    </div>

                    <div className="mr-auto">
                      <div
                        className="shadow-sm p-3 mb-3 bg-white text-danger rounded"
                        style={{ height: "6rem", width: "11rem" }}
                      >
                        <h5>
                          <span
                            className="col-md-12 font-weight-bold"
                            style={{ fontSize: "38px" }}
                          >
                            {stat.deactivatedSim}
                          </span>
                          <p style={{ fontSize: "16px" }}>Deactivated SIMs </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ... */}
                <table
                  className="mt-3 table shadow-sm p-1 mb-1 bg-white text-dark rounded"
                  style={{ minHeight: "200px" }}
                >
                  {order_state === "getting" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Spinner useSpinnarLarge={true} />
                    </div>
                  )}
                  {order_state === "error" && error && (
                    <div>{error.message}</div>
                  )}
                  {order_state === "gotten" &&
                    data &&
                    data.orders.data.length === 0 && (
                      <div>No orders available</div>
                    )}
                  {order_state === "gotten" &&
                    data &&
                    data.orders.data.length > 0 && (
                      <>
                        {" "}
                        <thead>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr 1fr",
                            }}
                          >
                            <th scope="col">INVOICE NO</th>
                            {/* <th scope="col">NO OF SIMs</th> */}
                            <th scope="col">AMOUNT(USD)</th>
                            <th scope="col">DATE</th>
                            <th scope="col">PAYMENT STATUS</th>
                          </tr>
                        </thead>
                        {console.log(data.orders.data)}
                        {data.orders.data.map((datum, id) => (
                          <tbody key={datum._id}>
                            <tr
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                              }}
                            >
                              <td>{id}</td>
                              {/* <td>{datum.numberOfSim}</td> */}
                              <td>{datum.amount}</td>
                              <td>{formateDate(datum.createdAt).date}</td>
                              <td>
                                {datum.status === "paid" && (
                                  <div className="col-md-3 mt-2 text-success d-none d-sm-inline">
                                    {datum.status}
                                  </div>
                                )}
                                {datum.status === "pending" && (
                                  <div className="col-md-3 mt-2 text-danger d-none d-sm-inline">
                                    {datum.status}
                                  </div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <Pagination
                          options={options}
                          currentPage={data.orders.meta.currentPage}
                          totalPages={data.orders.meta.totalPages}
                          onPageChange={handleChange}
                          rowsPerPage={Number(data.orders.meta.count)}
                          totalItems={data.orders.meta.totalItems}
                        />
                      </>
                    )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SimInventory;
