import { motion } from "framer-motion";
import Navbar from "../layout/Navbar";
import WillIncludeSidebar from "../../common/WillIncludeSidebar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setCurrency,
  setExchangeRate,
  setFundWallet,
  setHistory,
  setSimClicked,
  setWalletItem,
  setWallet,
  setSimItemDropdown,
} from "../../features/appSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import RadioButton from "../../common/RadioButton";
import chevronUp from "../../assets/circle-chevron-up.png";
import chevronDown from "../../assets/circle-chevron-down.png";
import axios from "axios";
import MyWallet from "./walletActivities/MyWallet";
import Snackbar from "../../common/Snackbar";
import FundWallet from "./walletActivities/FundWallet";
import History from "./walletActivities/History";

const Wallet = () => {
  const {
    walletStyle,
    fundWalletStyle,
    historyStyle,
    isWalletClicked,
    isFundWalletClicked,
    isHistoryClicked,
    fundWalletText,
    isPaynowClicked,
    exchangeRate,
  } = useSelector((state) => state.app.value);

  const dispatch = useDispatch();

  const [isCheckedNaira, setNairaChecked] = useState(false);
  const [isCheckedDollar, setDollarChecked] = useState(true);
  const [snackbarText, setSnackbarText] = useState("");
  const [isSnackbarVisible, setSnackbarVisibility] = useState(false);
  const [isSelectCurrencyDropdown, setSelectCurrencyDropdown] = useState(false);

  const onSelectCurrencyTapHandler = () => {
    setSelectCurrencyDropdown(!isSelectCurrencyDropdown);
  };

  const onWalletClickHandler = () => {
    dispatch(setWallet());
  };

  const onHistoryClickHandler = () => {
    dispatch(setHistory());
  };
  const onNairaClicked = () => {
    dispatch(setCurrency("₦"));
    setSnackbarText("Currency set to Naira (NGN)");

    setSnackbarVisibility(true);
    setTimeout(() => setSnackbarVisibility(false), 300);
  };
  const onDollarClicked = () => {
    dispatch(setCurrency("$"));
    setSnackbarText("Currency set to Dollar (USD)");

    setSnackbarVisibility(true);
    setTimeout(() => setSnackbarVisibility(false), 300);
  };

  const onPayNowHandler = () => {};

  const getExchangeRate = () => {
    axios
      .get(
        "https://api.apilayer.com/exchangerates_data/convert?to=NGN&from=USD&amount=1",
        {
          headers: { apikey: process.env.REACT_APP_EXCHANGE_RATE_API_KEY },
        }
      )
      .then((res) => dispatch(setExchangeRate(res.data.result)))
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    dispatch(setSimItemDropdown(false));
    dispatch(setSimClicked(false));
    dispatch(setWalletItem());

    dispatch(setWallet());

    // getExchangeRate()
  }, []);

  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="text-left mt-5 ml-3 mb-5"
          >
            <Snackbar
              snackbarText={snackbarText}
              showSnackbar={isSnackbarVisible}
            />

            <div className="container-fluid">
              <div className="container-fluid">
                <h5 className="font-weight-bold" style={{ fontSize: "28px" }}>
                  Wallet
                </h5>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="continer-fluid mt-5 ml-3 mr-auto">
                    <button
                      className={walletStyle}
                      onClick={onWalletClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-wallet"></i>
                      </span>
                      <span> BILLING</span>
                    </button>

                    <button
                      className={historyStyle}
                      onClick={onHistoryClickHandler}
                    >
                      <span>
                        <i className="fs-4 bi-history"></i>
                      </span>
                      <span>TRANSACTION HISTORY</span>
                    </button>
                  </div>

                  <div className="shadow-sm selectCurrencyButton ml-3 mr-3">
                    <div
                      className="container-fluid"
                      onClick={onSelectCurrencyTapHandler}
                    >
                      <div className="row d-flex">
                        <div className="mt-1 ml-2">Select Currency</div>
                        <div className="mt-1 ml-auto mr-2">
                          {isSelectCurrencyDropdown ? (
                            <img src={chevronUp} alt="icon" width="25px" />
                          ) : (
                            <img src={chevronDown} alt="icon" width="25px" />
                          )}
                        </div>
                      </div>
                    </div>

                    {isSelectCurrencyDropdown && (
                      <motion.div
                        initial={{ height: "0rem" }}
                        animate={{
                          height: "auto",
                          transition: { duration: 0.5 },
                        }}
                        exit={{ y: window.innerHeight }}
                        className="shadow-sm selectCurrencyDropdown mt-2"
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { duration: 1.5 },
                          }}
                          exit={{ opacity: 0 }}
                          className="container-fluid p-3"
                        >
                          <div className="ml-3">
                            <div className="ml-3 mt-2">
                              <RadioButton
                                id="usd"
                                label={<small>USD</small>}
                                value="usd"
                                checked={isCheckedDollar}
                                onChangeHandler={() => {
                                  setDollarChecked(true);
                                  setNairaChecked(false);
                                  onDollarClicked();
                                  setSelectCurrencyDropdown(false);
                                }}
                              />
                            </div>
                            {/* <RadioButton
                              id="ngn"
                              label={<small>NGN</small>}
                              value="ngn"
                              checked={isCheckedNaira}
                              onChangeHandler={() => {
                                setNairaChecked(true);
                                setDollarChecked(false);
                                onNairaClicked();
                                setSelectCurrencyDropdown(false);
                              }}
                            /> */}
                          </div>
                        </motion.div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </div>

              {isWalletClicked && <MyWallet />}
              {isHistoryClicked && <History />}
            </div>
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default Wallet;
