import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FloatingLabelTextInput from "../common/FloatingLabelTextInput";
import logoGreen from "../assets/logo_green.png";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Spinner from "../common/Spinner";
import { EMAIL } from "../app/constants";
import { login } from "../app/actions/authAction/authAction";

const Login = () => {
  const { errorEmail, errorPassword } = useSelector(
    (state) => state.error.value
  );
  const { isAuthenticating, error, data } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isDeactivated, setDeactivated] = useState(false);

  const [isLoginClicked, setIsLoginClicked] = useState(false);

  const navigate = useNavigate();

  const forgotPasswordHandler = () => {
    navigate("/forgot-password");
  };

  const signupHandler = () => {
    navigate("/sign-up");
  };

  const loginHandler = () => {
    if (!email) {
      setEmailError("Pls input an email address");
      return;
    }
    if (!password) {
      setPasswordError("Pls input a password");
      return;
    }
    const data = {
      email,
      password,
    };
    dispatch(login(data));
  };

  useEffect(() => {
    if (data) {
      navigate("/dashboard");
    }
  }, [data, navigate]);

  const LoginButton = () => {
    return (
      <div>
        {isAuthenticating ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="btn btn-light btn-block text-light"
            disabled={isAuthenticating}
            style={{ height: "3rem" }}
          >
            <Spinner />
          </motion.button>
        ) : (
          <button
            className="btn btn-color btn-block btn-control text-light"
            onClick={loginHandler}
            style={{ height: "3rem" }}
          >
            Login
          </button>
        )}
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="row">
        <div
          className="col-md-7 bg-login"
          id="mobile_only"
          style={{ position: "sticky", top: "0px" }}
        />
        <div className="col-md-5 mt-5">
          <div className="text-left ml-3 mb-5">
            <img src={logoGreen} alt="logo" />
          </div>
          <div className="text-left ml-3 mb-5">
            <h4>Login to your account</h4>
          </div>
          <div className="container m-auto">
            <FloatingLabelTextInput
              label="Email"
              type="email"
              value={email}
              onChangeHandler={(e) => setEmail(e.target.value)}
              disabled={isAuthenticating}
              error={!email && emailError ? emailError : ""}
            />

            <FloatingLabelTextInput
              label="Password"
              type="password"
              value={password}
              onChangeHandler={(e) => setPassword(e.target.value)}
              disabled={isAuthenticating}
              error={!password && passwordError ? passwordError : ""}
            />
            <div className="text-left mt-2 mb-5 txt-color">
              <h5
                className="btn bg-white txt-color"
                onClick={forgotPasswordHandler}
              >
                Forgot Password?
              </h5>
            </div>

            {error && (
              <small className="text-danger">
                {error.message || "Something went wrong try again"}
              </small>
            )}

            <LoginButton />

            <div className="text-left mt-3 mb-5 text-dark">
              <span className="btn bg-white txt-color">
                Don't have an account?
              </span>
              <span className="btn bg-white txt-color" onClick={signupHandler}>
                Signup
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;
