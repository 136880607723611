import React from "react";
import SimData from "../../data/SimData";
import { simData } from "../../data/dataJson";
import { motion } from "framer-motion";
import CheckBox from "../../../common/CheckBox";
import { useState } from "react";
import active from "../../../assets/active.png";
import inactive from "../../../assets/inactive.png";
import suspended from "../../../assets/suspended.png";
import menu from "../../../assets/menu.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setManageSimItem,
  setManageSimSelected,
} from "../../../features/appSlice";
import { useNavigate } from "react-router-dom";
import { getAllSims } from "../../../app/actions/simAction/simAction";
import Spinner from "../../../common/Spinner";
import Select from "react-select";
import Pagination from "../../../common/Pagination";

const ManageSIMs = () => {
  const [value, setValue] = useState([]);
  const [sim, setSim] = useState("");
  const [send, setSend] = useState(false);
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const {
    state,
    allSims,
    errorAllSims,
    filteredData,
    searchedImsi,
    searchedIccid,
    status,
  } = useSelector((state) => state.sim);

  const navigate = useNavigate();
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const actionBtn = {
    background: "black",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    marginBottom: "2px",
  };
  const actionModal = {
    background: "white",
    position: "absolute",
    top: "-100px",
    right: "-100%",
    width: "150px",
    minHeight: "80px",
    justifyContent: "start",
    justifyItems: "start",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: "1fr",
  };
  const sendBtn = {
    border: "0",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    color: "#464F60",
    background: "transparent",
    padding: "4px 10px",
    width: "95%",
    textAlign: "start",
    marginInline: "auto",
  };
  const hoverBtn = {
    background: "#CFF4D2",
    color: "#464F60",
    borderRadius: "4px",
  };
  const onSendSMSHandler = () => {
    const params = sim ? [sim] : value;
    if (!sim && value.length === 0) {
      return;
    }
    navigate(`/send-sms/sims`, { state: { sims: params } });
  };
  const onViewSimHandler = () => {
    const params = sim ? [sim] : value;
    if (!sim && value.length === 0) {
      return;
    }
    navigate(`/sims/sim-card/${params}`);
  };
  const handleCheck = (e, id) => {
    if (value.includes(id)) {
      setValue((prev) => {
        prev.filter((item) => item !== id);
      });
    } else {
      setValue((prev) => [...prev, id]);
    }
    setSim("");
  };
  // const handleSend = () => {

  // }

  const handleChange = (page, limit) => {
    dispatch(
      getAllSims({
        page,
        limit,
      })
    );
  };

  useEffect(() => {
    dispatch(setManageSimItem());
    dispatch(
      getAllSims({
        page: 1,
        limit: 10,
      })
    );
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <div className="container-fluid">
        <div className="container-fluid">
          <div className="">
            <div className="container-fluid">
              <div
                className="mb-1 bg-light text-dark rounded mt-5"
                style={{ height: "100%" }}
              >
                <div className="container-fluid">
                  <div className="row d-flex h-100">
                    <button className="mr-auto mb-3 mr-5 btn btn-color btn-control text-light">
                      {/* <i class="bi bi-list"></i> */}
                      <img
                        className="mr-3 mb-1"
                        src={menu}
                        alt="icon"
                        width="18px"
                      />
                      <span> MANAGE SIMs</span>
                    </button>

                    <div className="ml-auto mr-1 mb-3 px-0 btn-empty-light" />

                    <button
                      className="ml-1 mb-3 btn btn-color-dark btn-control text-light"
                      onClick={onSendSMSHandler}
                      disabled={true}
                    >
                      <span>Send SMS</span>
                    </button>
                    <button className="ml-1 mb-3 btn btn-success btn-control text-light">
                      <span>Activate</span>
                    </button>
                    <button className="ml-1 mb-3 btn btn-danger btn-control text-light">
                      <span>Suspend</span>
                    </button>
                    <button className="ml-1 mb-3 btn btn-color btn-control text-light">
                      <span>Create Plan</span>
                    </button>
                    <button className="ml-1 mb-3 btn btn-color-dark btn-control text-light">
                      <span>Detarch Plan</span>
                    </button>
                    <button className="ml-1 mb-3 btn btn-color btn-control text-light">
                      <span>Download As File</span>
                    </button>

                    {/*  */}

                    {state === "loading" && (
                      <div
                        style={{
                          minHeight: "300px",
                          display: "grid",
                          placeItems: "center",
                        }}
                        className="btn-block"
                      >
                        <Spinner useSpinnarLarge={true} />
                      </div>
                    )}

                    {allSims?.data?.length === 0 && !state && (
                      <div
                        style={{
                          minHeight: "300px",
                          display: "grid",
                          placeItems: "center",
                        }}
                        className="btn-block"
                      >
                        <div>No sim available kindly create one</div>
                      </div>
                    )}
                    {filteredData.length === 0 &&
                      (searchedIccid || searchedImsi || status) && (
                        <div
                          style={{
                            minHeight: "300px",
                            display: "grid",
                            placeItems: "center",
                          }}
                          className="btn-block"
                        >
                          <div>No result found</div>
                        </div>
                      )}
                    <div
                      className="btn-block"
                      style={{
                        background: "#fff",
                        overflowX: "auto",
                        maxWidth: "100%",
                      }}
                    >
                      {filteredData && filteredData.length > 0 && !state && (
                        <>
                          <table className="table shadow-sm p-1 mb-1 bg-white text-dark rounded">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">STATUS</th>
                                <th scope="col">ICCID</th>
                                <th scope="col">IMSI</th>
                                <th scope="col">MSISDN</th>
                                <th scope="col">PLAN</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>

                            {filteredData.map((data, index) => (
                              <tbody key={data._id}>
                                <tr>
                                  <td></td>
                                  <td scope="row">
                                    <CheckBox
                                      label={data.label}
                                      id={data._id}
                                      value={value.includes(data.iccid)}
                                      onCheckedHandler={(e) =>
                                        handleCheck(e, data.iccid)
                                      }
                                    />
                                  </td>
                                  {data.status === "ACTIVE" && (
                                    <td>
                                      <img
                                        className="rounded-circle"
                                        src={active}
                                        alt="company logo"
                                        style={{
                                          width: "20px",
                                          marginRight: "8px",
                                        }}
                                        title="status"
                                      />
                                    </td>
                                  )}
                                  {data.status === "INACTIVE" && (
                                    <td>
                                      <img
                                        className="rounded-circle"
                                        src={inactive}
                                        alt="company logo"
                                        style={{
                                          width: "20px",
                                          marginRight: "8px",
                                        }}
                                        title="status"
                                      />
                                    </td>
                                  )}
                                  {data.status === "SUSPENDED" && (
                                    <td>
                                      <img
                                        className="rounded-circle"
                                        src={suspended}
                                        alt="company logo"
                                        style={{
                                          width: "20px",
                                          marginRight: "8px",
                                        }}
                                        title="status"
                                      />
                                    </td>
                                  )}

                                  <td>{data.iccid}</td>
                                  <td>{data.imsiMsisdnPairs[0].imsi}</td>
                                  <td>{data.imsiMsisdnPairs[0].msisdn}</td>
                                  <td>{data.planId ? data.planId : "Null"}</td>
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setSim(data._id)}
                                  >
                                    <div style={actionBtn}></div>
                                    <div style={actionBtn}></div>
                                    <div style={actionBtn}></div>
                                    {sim === data._id && (
                                      <div style={actionModal}>
                                        <button
                                          style={{
                                            ...sendBtn,
                                            ...(send && hoverBtn),
                                          }}
                                          onClick={onSendSMSHandler}
                                          onMouseEnter={() => setSend(true)}
                                          onMouseLeave={() => setSend(false)}
                                          disabled={true}
                                        >
                                          Send SMS
                                        </button>
                                        <button
                                          style={{
                                            ...sendBtn,
                                            ...(view && hoverBtn),
                                          }}
                                          onClick={onViewSimHandler}
                                          onMouseEnter={() => setView(true)}
                                          onMouseLeave={() => setView(false)}
                                        >
                                          View
                                        </button>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                          <Pagination
                            options={options}
                            totalPages={allSims.meta.totalPages}
                            totalItems={allSims.meta.totalItems}
                            currentPage={Number(allSims.meta.currentPage)}
                            onPageChange={handleChange}
                            rowsPerPage={Number(allSims.meta.count)}
                          />
                        </>
                      )}
                      {filteredData.length === 0 &&
                        !searchedIccid &&
                        !searchedImsi &&
                        !status &&
                        allSims?.data?.length > 0 &&
                        !state && (
                          <>
                            <table className="table shadow-sm p-1 mb-1 bg-white text-dark rounded">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col">STATUS</th>
                                  <th scope="col">ICCID</th>
                                  <th scope="col">IMSI</th>
                                  <th scope="col">MSISDN</th>
                                  <th scope="col">PLAN</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              {allSims.data.map((data, index) => (
                                <tbody key={data._id}>
                                  <tr>
                                    <td></td>
                                    <td scope="row">
                                      <CheckBox
                                        label={data.label}
                                        id={data._id}
                                        value={value.includes(data.iccid)}
                                        onCheckedHandler={(e) =>
                                          handleCheck(e, data.iccid)
                                        }
                                      />
                                    </td>
                                    {data.status === "ACTIVE" && (
                                      <td>
                                        <img
                                          className="rounded-circle"
                                          src={active}
                                          alt="company logo"
                                          style={{
                                            width: "20px",
                                            marginRight: "8px",
                                          }}
                                          title="status"
                                        />
                                      </td>
                                    )}
                                    {data.status === "INACTIVE" && (
                                      <td>
                                        <img
                                          className="rounded-circle"
                                          src={inactive}
                                          alt="company logo"
                                          style={{
                                            width: "20px",
                                            marginRight: "8px",
                                          }}
                                          title="status"
                                        />
                                      </td>
                                    )}
                                    {data.status === "SUSPENDED" && (
                                      <td>
                                        <img
                                          className="rounded-circle"
                                          src={suspended}
                                          alt="company logo"
                                          style={{
                                            width: "20px",
                                            marginRight: "8px",
                                          }}
                                          title="status"
                                        />
                                      </td>
                                    )}

                                    <td>{data.iccid}</td>
                                    <td>{data.imsiMsisdnPairs[0].imsi}</td>
                                    <td>{data.imsiMsisdnPairs[0].msisdn}</td>
                                    <td>
                                      {data.planId ? data.planId : "Null"}
                                    </td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "relative",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setSim(data._id)}
                                    >
                                      <div style={actionBtn}></div>
                                      <div style={actionBtn}></div>
                                      <div style={actionBtn}></div>
                                      {sim === data._id && (
                                        <div style={actionModal}>
                                          <button
                                            style={{
                                              ...sendBtn,
                                              ...(send && hoverBtn),
                                            }}
                                            onClick={onSendSMSHandler}
                                            onMouseEnter={() => setSend(true)}
                                            onMouseLeave={() => setSend(false)}
                                            disabled={true}
                                          >
                                            Send SMS
                                          </button>
                                          <button
                                            style={{
                                              ...sendBtn,
                                              ...(view && hoverBtn),
                                            }}
                                            onClick={onViewSimHandler}
                                            onMouseEnter={() => setView(true)}
                                            onMouseLeave={() => setView(false)}
                                          >
                                            View
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                            <Pagination
                              options={options}
                              totalPages={allSims.meta.totalPages}
                              totalItems={allSims.meta.totalItems}
                              currentPage={Number(allSims.meta.currentPage)}
                              onPageChange={handleChange}
                              rowsPerPage={Number(allSims.meta.count)}
                            />
                          </>
                        )}
                    </div>
                    {errorAllSims && (
                      <div className="text-danger">{errorAllSims.message}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ManageSIMs;
