import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "../features/errorSlice";
// import authReducer from '../features/authSlice'
import appReducer from "../features/appSlice";
import authReducer from "./reducers/authReducer/authReducer";
import simReducer from "./reducers/simReducer/simReducer";
import userReducer from "./reducers/userReducer/userReducer";
import settingsReducer from "./reducers/settingsReducer/settingsReducer";
import groupReducer from "./reducers/groupReducer/groupReducer";
import purchaseReducer from "./reducers/purchaseReducer/purchaseReducer";
import billReducer from "./reducers/billReducer/billReducer";
import sendSMS from "./reducers/sendSMS/sendSMS";
import inventoryReducer from "./reducers/inventoryReducer/inventoryReducer";

const store = configureStore({
  reducer: {
    error: errorReducer,
    auth: authReducer,
    sim: simReducer,
    app: appReducer,
    user: userReducer,
    settings: settingsReducer,
    group: groupReducer,
    purchase: purchaseReducer,
    billing: billReducer,
    sendSMS,
    inventory: inventoryReducer,
  },
});

export default store;
