import { motion } from "framer-motion";
import CheckBox from "../../../common/CheckBox";
import { historyData } from "../../data/dataJson";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import Spinner from "../../../common/Spinner";
import SuccessModal from "../../../common/SuccessModal";
import Pagination from "../../../common/Pagination";
import formateDate, { months } from "../../../utils/formatDate";
import { getAllBills } from "../../../app/actions/billAction/billAction";

const History = () => {
  const { exchangeRate, currency, curreny_text } = useSelector(
    (state) => state.app.value
  );
  const dispatch = useDispatch();
  const { loading_state, error, bills, payBillSuccess, payBillError } =
    useSelector((state) => state.billing);
  const [availableFunds, setAvailableFunds] = useState(100067); // Dollar value rendered to 2 decumal place

  const [value, setValue] = useState("");
  const [isHistory, setHistory] = useState(false);
  const [isDownloadClicked, setDownloadCLicked] = useState(false);
  const [successModal, showSuccessModal] = useState(false);
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const onBackClickHandler = () => {
    if (!isDownloadClicked) setHistory(true);
  };

  const onViewHandler = (data) => {
    setHistory(false);
  };

  const onDownloadClicked = () => {
    setDownloadCLicked(true);

    setTimeout(() => {
      showSuccessModal(true);
      setDownloadCLicked(false);
    }, 3000);
  };

  const onModalHideHandler = () => {
    showSuccessModal(false);
    setHistory(true);
  };
  const handleChange = (page, limit) => {
    dispatch(
      getAllBills({
        page,
        limit,
      })
    );
  };

  useEffect(() => {
    setHistory(true);
  }, []);
  useEffect(() => {
    if (loading_state === "got_bills") {
      return;
    } else {
      dispatch(
        getAllBills({
          page: 1,
          limit: 10,
        })
      );
    }
  }, [dispatch, loading_state]);

  let content;
  if (loading_state === "getting_bills") {
    content = <Spinner useSpinnarLarge={true} />;
  }
  if (loading_state === "got_bills") {
    const filteredBills = bills.bills.data.filter((item) => item.isPaid);
    content = (
      <motion.div
        initial={{ opacity: 0, background: "white", padding: "20px" }}
        animate={{
          opacity: 1,
          background: "white",
          paddingBlock: "20px",
          transition: { duration: 0.5 },
        }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid">
          <div
            className="shadow-sm p-2 bg-white text-dark rounded mt-5"
            style={{ height: "100%" }}
          >
            <div className="container-fluid">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <div className="text-left mt-3 mb-3">
                  <h5 className="font-weight-bold">Transaction History</h5>
                </div>

                {filteredBills.length === 0 && <div>No invoice available</div>}
                {filteredBills.length > 0 && (
                  <>
                    <table className="table shadow-sm p-1 mb-4 mt-5 bg-white text-dark rounded">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">INVOICE NO</th>
                          <th scope="col">AMOUNT</th>
                          <th scope="col">DATE</th>
                          {/* <th scope="col">EMAIL</th> */}
                          <th scope="col">STATUS</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      {filteredBills.map((datum) => (
                        <tbody key={datum._id}>
                          <tr>
                            <td></td>
                            <td>
                              <CheckBox
                                id={datum.id}
                                value={""}
                                onCheckedHandler={() => {}}
                              />
                            </td>
                            <td>{months[datum.month]}</td>
                            <td>
                              <CurrencyFormat
                                value={
                                  curreny_text == "NGN"
                                    ? datum.amount * bills.conversionRate
                                    : datum.amount
                                }
                                displayType={"text"}
                                isNumericString={true}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {curreny_text}
                                    {value}
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              {datum.month}/{datum.year}
                            </td>
                            {/* <td>hedristemitope2001@gmail.com</td> */}
                            <td
                              style={
                                datum.isPaid
                                  ? { color: "#77B285", fontSize: "14px" }
                                  : { color: "#CD4647", fontSize: "14px" }
                              }
                            >
                              {!datum.isPaid ? "Unpaid" : "Paid"}
                            </td>
                            <td>
                              <div
                                className="btn button-style"
                                style={{
                                  height: "35px",
                                  fontSize: "15px",
                                }}
                              >
                                Download
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <Pagination
                      options={options}
                      totalPages={bills.bills.meta.totalPages}
                      totalItems={bills.bills.meta.totalItems}
                      currentPage={Number(bills.bills.meta.currentPage)}
                      onPageChange={handleChange}
                      rowsPerPage={Number(bills.bills.meta.count)}
                    />
                  </>
                )}
              </motion.div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5"></div>

        <div className="col-md-8 ml-auto mr-auto mt-5 mb-3"></div>
      </motion.div>
    );
  }
  if (!loading_state && error) {
    content = <div>{error.message}</div>;
  }
  return content;
};

export default History;
