import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const getAllOrders = createAsyncThunk(
  "orders/get-all",
  async (params) => {
    try {
      const response = await api.get("/order", { params });
      if (response.data.success && response.data.data) {
        return response.data.data;
      } else {
        throw new Error(
          response.data.message || "Request unsuccessful, something went wrong"
        );
      }
    } catch (error) {
      throw new Error(
        error.data?.message || error.message || "Request unsuccessful"
      );
    }
  }
);
