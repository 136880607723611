import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../utils/api";

export const getAllBills = createAsyncThunk(
  "bills/get-all",
  async ({ page, limit }) => {
    try {
      const response = await api.get(
        `/monthly-bill?page=${page}&limit=${limit}`
      );

      if (response.data.success && response.data.data) {
        return response.data.data;
      } else {
        throw new Error(response.data?.message || "Request unsuccessful");
      }
    } catch (error) {
      throw new Error(
        error.data?.message || error.message || "Request unsuccessful"
      );
    }
  }
);

export const payBill = createAsyncThunk("bills/pay-bill", async (data, _) => {
  try {
    const response = await api.post("/monthly-bill/payBill", data);
    if (response.data.success && response.data.data) {
      return response.data.data;
    } else {
      throw new Error(response.data?.message || "Request unsuccessful");
    }
  } catch (error) {
    throw new Error(
      error.data?.message || error.message || "Request unsuccessful"
    );
  }
});
