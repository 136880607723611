import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import SelectListGroup from "../../../common/SelectListGroup";
import WillIncludeSidebar from "../../../common/WillIncludeSidebar";
import Navbar from "../../layout/Navbar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TextArea from "../../../common/TextArea";
// import backIcon from "../../../assets/back-icon.png";
import nextIcon from "../../../assets/nextIcon.svg";
import SuccessModal from "../../../common/SuccessModal";
import Spinner from "../../../common/Spinner";
import Loading from "../../../common/Loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { sendSMS } from "../../../app/actions/sendSMSAction/sendSMSAction";
import CheckBox from "../../../common/CheckBox";
import { resetState } from "../../../app/reducers/sendSMS/sendSMS";
import * as view from "./viewSimStyle";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import mcc_mnc_list from "mcc-mnc-list";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getSim } from "../../../app/actions/simAction/simAction";

// Fix for missing marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// const data = [
//   {
//     name: "Week 1",
//     Sms: 4000,
//     Data: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Week 2",
//     Sms: 3000,
//     Data: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Week 3",
//     Sms: 2000,
//     Data: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Week 4",
//     Sms: 2780,
//     Data: 3908,
//     amt: 2000,
//   },
// ];

const calculateDailyUsage = (rawData) => {
  const dailyUsage = {};

  // Process raw data to populate dailyUsage with the latest entry for each day
  rawData.forEach((entry) => {
    const createdAt = new Date(entry.dataFor);
    const day = createdAt.getDate();
    const month = createdAt.toLocaleString("default", { month: "short" });
    const dayKey = `${month}-${day}`;

    if (
      !dailyUsage[dayKey] ||
      new Date(entry.createdAt) > new Date(dailyUsage[dayKey].createdAt)
    ) {
      dailyUsage[dayKey] = {
        createdAt: entry.createdAt, // Store the createdAt for comparison
        Sms: entry.type === "SMS" ? entry.quantity : 0,
        Data: entry.type === "DATA" ? entry.quantity : 0,
      };
    } else {
      // If entry is not more recent, ensure Sms/Data fields are updated correctly
      if (entry.type === "SMS") {
        dailyUsage[dayKey].Sms = entry.quantity;
      } else if (entry.type === "DATA") {
        dailyUsage[dayKey].Data = entry.quantity;
      }
    }
  });

  // Sort the keys by date
  const sortedKeys = Object.keys(dailyUsage).sort((a, b) => {
    const [monthA, dayA] = a.split("-");
    const [monthB, dayB] = b.split("-");
    const dateA = new Date(`${monthA} ${dayA}, ${new Date().getFullYear()}`);
    const dateB = new Date(`${monthB} ${dayB}, ${new Date().getFullYear()}`);
    return dateA - dateB;
  });

  return sortedKeys.map((day) => ({
    name: day,
    Sms: dailyUsage[day].Sms,
    Data: dailyUsage[day].Data,
  }));
};

const calculateTotalDataUsed = (rawData) => {
  let totalDataUsed = 0;

  rawData.forEach((entry) => {
    if (entry.type === "DATA") {
      totalDataUsed += entry.quantity;
    }
  });

  return totalDataUsed;
};

const ViewSim = () => {
  const { sim, simError, simState } = useSelector((state) => state.sim);
  const dispatch = useDispatch();
  const { id: sims } = useParams("id");
  console.log(sims);
  // const sims = queryParams.get("sims");
  console.log(sims, sim);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/sim-cards");
  };
  const getFirstOfNextMonth = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // getMonth() returns 0 for January, 1 for February, etc.

    // Calculate the next month
    const nextMonth = (currentMonth + 1) % 12; // Ensures that December (11) + 1 = January (0)
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear; // If December, increment the year

    // Create a new date for the 1st of the next month
    const firstOfNextMonth = new Date(nextYear, nextMonth, 1);

    return firstOfNextMonth;
  };
  const getCountryFromMCCMNC = (mcc, mnc) => {
    const f = mcc_mnc_list.filter({ mccmnc: `${mcc}${mnc}` });
    console.log(f);

    return f;
  };
  useEffect(() => {
    dispatch(
      getSim({
        id: sims,
      })
    );
  }, [dispatch, sims]);
  const tryAgain = () => {
    dispatch(
      getSim({
        id: sims,
      })
    );
  };

  let content;
  if (simState === "loading") {
    content = <Spinner useSpinnarLarge={true} />;
  }
  if (sim && simState !== "loading") {
    const { mcc, mnc } = sim.extras.content[0]?.lastConnectedNetwork || "";

    let brand = "UNKNOWN";
    let bands = "UNKNOWN";
    if (mcc && mnc) {
      brand = getCountryFromMCCMNC(mcc, mnc)[0].brand;
      bands = getCountryFromMCCMNC(mcc, mnc)[0].bands;
    }
    const data = calculateDailyUsage(sim.sessions.data);
    console.log(data);

    content = (
      <div style={view.container}>
        <div style={view.containerHead}>
          <div style={view.head}>
            <span>Basic sim Information</span>
            <span style={view.line}></span>
          </div>
          <div style={view.headBody}>
            <span style={view.headContent}>
              <span style={view.headText}>IMEI</span>
              <span style={view.headCap}>
                {sim.extras.content[0]?.subscriberIdentifiers?.imei ||
                  "- - - - - - - - - -"}
              </span>
            </span>
            <span style={view.headContent}>
              <span style={view.headText}>ICCID</span>
              <span style={view.headCap}>{sim.sim.iccid}</span>
            </span>
            <span style={view.headContent}>
              <span style={view.headText}>Sim Type</span>
              <span style={view.headCap}>Regular</span>
            </span>
            <span style={view.headContent}>
              <span style={view.headText}>Data Used</span>
              <span style={view.headCap}>
                {parseFloat(
                  (calculateTotalDataUsed(sim.sessions.data) / 1024).toFixed(2)
                )}
                MB
              </span>
            </span>
            <span style={view.headContent}>
              <span style={view.headText}>Status</span>
              <span
                style={
                  sim.sim.status === "ACTIVE"
                    ? {
                        color: "#1D6900",
                      }
                    : { color: "red" }
                }
              >
                {sim.sim.status}
              </span>
            </span>
          </div>
          <div style={{ ...view.head, marginTop: "30px" }}>
            <span>Other sim Information</span>
            <span style={view.line}></span>
          </div>
          <div style={view.body}>
            <div>
              <div>Last 15 records for data(in KB) and sms usage</div>
              <div style={view.chart}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Sms"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="Data" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={view.map}>
              <MapContainer
                center={[
                  sim.extras?.content[0]?.lastKnownLocation?.latitude || 0,
                  sim.extras?.content[0]?.lastKnownLocation?.longitude || 0,
                ]}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: "400px", width: "100%" }} // Ensure proper dimensions
              >
                <TileLayer
                  attribution=' <a href="https://www.openstreetmap.org/copyright"></a> '
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    sim.extras?.content[0]?.lastKnownLocation?.latitude || 0,
                    sim.extras?.content[0]?.lastKnownLocation?.longitude || 0,
                  ]}
                >
                  <Popup>The Sim is last used in this location</Popup>
                </Marker>
              </MapContainer>
            </div>
            <div style={view.box}>
              <div style={view.boxHead}>Connectivity Information</div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Sim Network</span>
                <span> {brand}</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Connectivity Technology</span>
                <span>4G</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Status</span>
                <span> Active</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Cell Information</span>
                <span> {bands}</span>
              </div>
            </div>
            <div style={view.box}>
              <div style={view.boxHead}>Billing Plan</div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Type</span>
                <span> Regular</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Price</span>
                <span>${sim.plan.price}</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Data</span>
                <span> {Number(sim.plan.dataCapInKb) / 1024}MB</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>SMS </span>
                <span> {sim.plan.smsCap} sms</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Period </span>
                <span> Monthly</span>
              </div>
              <div style={view.boxContent}>
                <span style={view.boxText}>Next Renewal Date </span>
                <span>{getFirstOfNextMonth().toDateString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (simError && simState === "error") {
    content = (
      <div>
        <span>{simError.message}, </span>
        <button onClick={tryAgain}>try again</button>{" "}
      </div>
    );
  }
  return (
    <div>
      <Navbar key="nav" />
      <div className="container-fluid">
        <WillIncludeSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="px-5 py-2"
          >
            <div style={view.header}>
              <span
                style={{
                  ...view.textBig,
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleGoBack}
              >
                SIMS
              </span>
              <img className="mr-3" src={nextIcon} alt="icon" width="10px" />

              <span style={view.textBig}>Label</span>
            </div>
            {content}
          </motion.div>
        </WillIncludeSidebar>
      </div>
    </div>
  );
};

export default ViewSim;
