import { createSlice } from "@reduxjs/toolkit";
import { getAllBills, payBill } from "../../actions/billAction/billAction";

const initialState = {
  bills: "",
  loading_state: "",
  error: "",
  payBillError: null,
  payBillSuccess: "",
};

const billReducer = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetPayBill: (state) => {
      state.payBillError = null;
      state.payBillSuccess = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBills.pending, (state, action) => {
      return {
        ...state,
        loading_state: "getting_bills",
      };
    });
    builder.addCase(getAllBills.fulfilled, (state, action) => {
      return {
        ...state,
        bills: action.payload,

        loading_state: "got_bills",
      };
    });
    builder.addCase(getAllBills.rejected, (state, action) => {
      return {
        ...state,
        error: action.error,
        loading_state: "",
      };
    });
    builder.addCase(payBill.pending, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(payBill.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        payBillSuccess: "success",
        payBillError: null,
      };
    });
    builder.addCase(payBill.rejected, (state, action) => {
      console.log(action);
      return {
        ...state,
        payBillError: action.error,
      };
    });
  },
});
export const { resetPayBill } = billReducer.actions;
export default billReducer.reducer;
